import { StateCreator } from 'zustand';
import { PrompterMode, ConnectionState, EndpointRole } from '@fluidprompter/core';
import { IPrompterAttributesSlice } from './PrompterAttributesSlice';

export interface IPrompterModeSlice {
  sessionStartTimestamp: number;
  setSessionStartTimestamp: (sessionStartTimestamp: number) => void;
  prompterMode: PrompterMode;
  setPrompterMode: (prompterMode: PrompterMode, withBlanking?: boolean) => void;
  isPlaying: boolean;
  isPaused: boolean;
  isEditing: boolean;
  isAnimating: boolean;
  play: () => void;
  pause: (withBlanking?: boolean) => void;
  edit: () => void;
  ensureAnimating: () => void;
  stopAnimating: () => void;
  isBlanking: boolean;
  setBlanking: (blanking?: boolean) => void;
  unsetBlanking: () => void;

  reset: () => void;

  websocketState: ConnectionState;
  setWebsocketState: (connectionState: ConnectionState) => void;
}

const createPrompterModeSlice: StateCreator<
  IPrompterAttributesSlice & IPrompterModeSlice,
  [],
  [],
  IPrompterModeSlice
> = (set, get) => ({
  sessionStartTimestamp: 0,
  setSessionStartTimestamp: (sessionStartTimestamp: number) => set(() => ({ sessionStartTimestamp })),
  prompterMode: PrompterMode.Editing,
  setPrompterMode: (prompterMode: PrompterMode, withBlanking?: boolean) => {
    set((state) => {
      let proposedMode = prompterMode;
      if(
        proposedMode === PrompterMode.Editing
          && state.endpointRole === EndpointRole.Viewer
      ) {
        proposedMode = PrompterMode.Paused;
      }

      const isBlanking = (proposedMode !== PrompterMode.Paused) ? false : ((withBlanking === true) || state.isBlanking);
      const stateChanges: Partial<IPrompterModeSlice> = {
        // isBlanking: (prompterMode !== PrompterMode.Paused) ? false : state.isBlanking,
        isBlanking,
        prompterMode: proposedMode,
        isPlaying: proposedMode === PrompterMode.Playing,
        isPaused: proposedMode === PrompterMode.Paused,
        isEditing: proposedMode === PrompterMode.Editing,
        isAnimating: proposedMode === PrompterMode.Playing ? true : false,
        // cursorHidden: (prompterMode === PrompterMode.Editing) ? false : state.cursorHidden,
      };
      if(!state.sessionStartTimestamp && proposedMode === PrompterMode.Playing) {
        // The first time we go into play mode, record our session start timestamp.
        stateChanges.sessionStartTimestamp = new Date().getTime();
      }
      return stateChanges;
    });
  },
  isPlaying: false,
  isPaused: false,
  isEditing: true,
  isAnimating: false,
  play: () => { get().setPrompterMode(PrompterMode.Playing); },
  pause: (withBlanking?: boolean) => { get().setPrompterMode(PrompterMode.Paused, withBlanking); },
  edit: () => { get().setPrompterMode(PrompterMode.Editing); },
  ensureAnimating: () => set((state) => {
    if(state.isAnimating) {
      // Returning the existing state will result in no change, and no hooks will be triggered.
      return state;
    }

    return { isAnimating: true };
  }),
  stopAnimating: () => set((state) => {
    if(state.isAnimating === false) {
      // Returning the existing state will result in no change, and no hooks will be triggered.
      return state;
    }

    return { isAnimating: false };
  }),
  isBlanking: false,
  setBlanking: (blanking = true) => {
    if(blanking) {
      get().setPrompterMode(PrompterMode.Paused, true);
    } else {
      set({ isBlanking: blanking });
    }
  },
  unsetBlanking: () => { get().setBlanking(false); },

  reset: () => {
    set((state) => {
      state.setPrompterMode(PrompterMode.Editing);
      state.setElapsedPlayTime(0);

      // sessionStartTimestamp: undefined
      // shotlogEntries: [],
      // nextShotlogEntry: undefined,
      return {
        framesPerSecond: 0,
        //
        sessionStartTimestamp: undefined,
        shotlogEntries: [],
        nextShotlogEntry: undefined,
      };
    });
  },

  websocketState: ConnectionState.Disconnected,
  setWebsocketState: (connectionState: ConnectionState) => set((state) => {
    // If no change in value, return existing state.
    if(state.websocketState === connectionState) {
      return state;
    }

    // Return new state
    return ({ websocketState: connectionState });
  }),
});

export default createPrompterModeSlice;