import { BaseControlMessage } from './BaseControlMessage';
export const HEARTBEATMESSAGE_KEY = 'heartbeat';
/**
 * Represents the HeartbeatMessage type.
 */
export class HeartbeatMessage extends BaseControlMessage {
    constructor(endpointRole, cloudConnectionState, cloudLatency) {
        super();
        this.type = HEARTBEATMESSAGE_KEY;
        this.senderTimestamp = Math.round(performance.now());
        this.endpointRole = endpointRole;
        this.cloudConnectionState = cloudConnectionState;
        this.cloudLatency = cloudLatency;
    }
    static fromJson(data) {
        if (data.type !== HEARTBEATMESSAGE_KEY) {
            throw new SyntaxError('Invalid JSON for HeartbeatMessage.');
        }
        const input = data;
        const instance = new HeartbeatMessage(input.endpointRole, input.cloudConnectionState, input.cloudLatency);
        instance.deserializeSenderInfo(data.sender);
        instance.senderTimestamp = input.senderTimestamp;
        return instance;
    }
    toJSON() {
        return {
            type: this.type,
            sender: this.sender?.toJSON(),
            senderTimestamp: this.senderTimestamp,
            endpointRole: this.endpointRole,
            cloudConnectionState: this.cloudConnectionState,
            cloudLatency: this.cloudLatency,
        };
    }
}
HeartbeatMessage.MESSAGE_NAME = HEARTBEATMESSAGE_KEY;
