import { BaseControlMessage } from './BaseControlMessage';
export const SYNCMESSAGE_KEY = 'sync';
/**
 * Represents the SyncMessage type.
 */
export class SyncMessage extends BaseControlMessage {
    /**
     * Constructor for a SyncMessage
     * @param userInitiated True if this sync message was initiated by human input and may change the leader.
     */
    constructor(userInitiated) {
        super();
        this.type = SYNCMESSAGE_KEY;
        this.userInitiated = userInitiated;
    }
    static fromJson(data) {
        if (data.type !== SYNCMESSAGE_KEY) {
            throw new SyntaxError('Invalid JSON for SyncMessage.');
        }
        const input = data;
        const instance = new SyncMessage(input.userInitiated);
        instance.deserializeSenderInfo(data.sender);
        instance.userInitiated = input.userInitiated;
        return instance;
    }
    toJSON() {
        return {
            type: this.type,
            sender: this.sender?.toJSON(),
            userInitiated: this.userInitiated,
        };
    }
}
SyncMessage.MESSAGE_NAME = SYNCMESSAGE_KEY;
