import React, { useEffect, useCallback, useRef } from 'react';
import { Editor, Descendant, Text, Path, Node } from 'slate';
import { ReactEditor } from 'slate-react';

import AppController, { useMessageHandler } from '../../controllers/AppController';
import useConfigurationStore, { MediaPanelPosition } from '../../state/ConfigurationStore';
import usePrompterSession, { MediaSourceType } from '../../state/PrompterSessionState';
import { shallow } from 'zustand/shallow';

import { useWhatChanged } from '@simbathesailor/use-what-changed';
import {
  IViewportMeta,
  IViewportInfo,
  IPrompterMeta,
  ISlateNodeMeta,
  IScriptNodeState,
  ScriptNodeStateChangedEvent,
  BaseControlMessage,
  IScriptPosition,
  PrompterMode,
  ScriptNodeStateChangeTypes,
  SegmentChangedMessage,
  NodeChangedMessage,
  SetScrollSpeedMessage,
} from '@fluidprompter/core';

import { IViewportFuncs } from '../PrompterViewport/usePrompterViewportFuncs';
import IPrompterPosition from '../../models/IPrompterPosition';

import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';
import useResizeObserver from '@react-hook/resize-observer';
import { PrompterEditor, PrompterElement } from '../../models/EditorTypes';

import calculateScrollPositionAdjustment from '../../utils/calculateScrollPositionAdjustment';
import calculateScrollSpeed from '../../utils/calculateScrollSpeed';

export interface PrompterResizedEventArgs {
  contentHeight: number,
  viewportHeight: number,
  lineHeight: number,
}

const measureScriptNode = (
  editor: PrompterEditor,
  nodePath: Path,
  prompterContentRect: DOMRect,
  flipVertical: boolean
): ISlateNodeMeta => {
  let currentNode: Node | undefined;
  try {
    const [editorNode] = Editor.node(editor, nodePath);
    currentNode = editorNode;
  } catch(err) {
    console.error(err);
    console.log(`Could not find node [${nodePath}]`);
  }
  if(!currentNode) {
    throw new Error('Slate could not locate node in DOM.');
  }
  const segmentEl = ReactEditor.toDOMNode(editor, currentNode);
  // console.log(`Found Slate Node ${index}!`, currentNode, segmentEl);

  const segmentRect = segmentEl.getBoundingClientRect();

  //
  // When .PrompterContent div is rotated via CSS `transform: rotateY(180deg)`, then
  // `getBoundingClientRect()` will return the co-ordinates of the element relative to the rotated
  // content div.
  //
  // Therefor items logically near the beginning of the script are now physically near the bottom
  // of the rotated/flipped prompter content.
  //
  // We can transform the segment top back to its "logical position" so that it does not change
  // whether being displayed flipped or not-flipped.
  //
  const segmentTop = flipVertical
    ? prompterContentRect.top + prompterContentRect.height - segmentRect.bottom
    : segmentRect.top - prompterContentRect.top;
  const segmentHeight = segmentRect.height;
  const segmentBottom = segmentTop + segmentHeight;

  //
  // Let's get the brower's computed styles for the target DOM element so that we can retrieve any
  // CSS margins being applied to the element.
  //
  const styles = window.getComputedStyle(segmentEl);
  const marginTop = parseFloat(styles['marginTop']);
  const marginBottom = parseFloat(styles['marginBottom']);
  const segmentLineHeight = parseInt(styles['lineHeight']);

  // TODO: Would this be more accurate using the body height for a prompter segment? Currently this is more a calculation of 'space/dimensions' than 'content length'.
  const segmentLines = Math.round(segmentHeight / segmentLineHeight);

  const isBlock = !(Text.isText(currentNode) || Editor.isInline(editor, currentNode));
  const isVoid = Editor.isVoid(editor, currentNode);

  return {
    top: segmentTop,
    height: segmentHeight,
    bottom: segmentBottom,
    prompterLines: segmentLines,
    marginTop,
    marginBottom,
    // offsetTop: segmentEl.offsetTop,       // includes: the top position, and margin of the element. the top padding, scrollbar and border of the parent
    // offsetHeight: segmentEl.offsetHeight  // The offsetHeight property returns the viewable height of an element (in pixels), including padding, border and scrollbar, but not the margin.
    children: [], // TODO get position of children elements
    childrenTop: 0,     // Default value - replaced when recursively measuring nodes
    childrenBottom: 0,  // Default value - replaced when recursively measuring nodes
    childrenHeight: 0,  // Default value - replaced when recursively measuring nodes
    isBlock,
    isVoid,
  };
};

const measureScriptNodes = (parentNodePath: Path, scriptNodes: Descendant[], editor: PrompterEditor, prompterContentRect: DOMRect, flipVertical: boolean): ISlateNodeMeta[] => {

  const nodesMeta = scriptNodes.map((scriptNode, index, array): ISlateNodeMeta => {
    const nodePath = [...parentNodePath, index];
    //
    // Measure the current node.
    //
    const currentNodeMeta: ISlateNodeMeta = measureScriptNode(editor, nodePath, prompterContentRect, flipVertical);

    //
    // Measure any children nodes, if appropriate.
    //
    const prompterElement = scriptNode as PrompterElement;
    if(prompterElement && prompterElement.children) {
      const childrenMeta = measureScriptNodes(nodePath, prompterElement.children, editor, prompterContentRect, flipVertical);

      currentNodeMeta.children = childrenMeta;
      currentNodeMeta.childrenTop = childrenMeta[0].top;
      currentNodeMeta.childrenBottom = childrenMeta[childrenMeta.length - 1].bottom;
      currentNodeMeta.childrenHeight = currentNodeMeta.childrenBottom - currentNodeMeta.childrenTop;
    }

    return currentNodeMeta;
  });

  // console.log(`Calculated ISlateNodeMeta for children at path [${parentNodePath}]`, nodesMeta);
  return nodesMeta;
};

/**
 * Returns our current IViewportInfo which factors the current scroll position into the static
 * information from IViewportMeta.
 * @param flipVertical
 * @param viewportMeta
 * @returns
 */
const getViewportInfo = (
  flipVertical: boolean,
  cuePositionPercentage: number,
  viewportMeta: IViewportMeta,
) => {
  const viewportEl = document.scrollingElement;
  if(!viewportEl) {
    throw new Error('document.scrollingElement undefined.');
  }

  const {
    viewportMarginTop,
    availableViewportHeight,
  } = viewportMeta;

  const viewportScrollTop = flipVertical
    ? viewportEl.scrollHeight - viewportEl.clientHeight - viewportEl.scrollTop + viewportMarginTop
    : viewportEl.scrollTop + viewportMarginTop;

  const viewportScrollBottom = viewportScrollTop + availableViewportHeight;

  const viewportScrollCuePosition = viewportScrollTop + (availableViewportHeight * cuePositionPercentage / 100);

  const viewportInfo: IViewportInfo = {
    ...viewportMeta,
    viewportScrollTop,
    viewportScrollBottom,
    viewportScrollCuePosition,
  };

  return viewportInfo;
};

interface IEvaluateNodesStateResults {
  /**
   * This is a collection of states for each node
   */
  nodeStates: IScriptNodeState[];

  /**
   * A collection of events to emit as a result of nodes' changing state in the prompter viewport.
   * ex: EnteringViewport, LeavingViewport, EnteringCue, LeavingCue
   */
  nodeEvents: BaseControlMessage[];
}

class EvaluateNodesStateResultsClass implements IEvaluateNodesStateResults {
  nodeStates: IScriptNodeState[];
  // nodeEvents: IControlMessage[];
  nodeEvents: BaseControlMessage[];

  scriptPosition: IScriptPosition | undefined;

  constructor() {
    this.nodeStates = [];
    this.nodeEvents = [];
  }

  addEvent(event: BaseControlMessage) {
    this.nodeEvents.push(event);
  }

  addEvents(events: BaseControlMessage[]) {
    if(!events) {
      return;
    }
    events.forEach((newEvent) => {
      this.nodeEvents.push(newEvent);
    });
  }

  setCurrentNode(
    nodePath: number[],
    nodeHeight: number,
    nodeChildrenTop: number,
    nodeChildrenHeight: number,
    position: number
  ) {
    if(nodePath.length === 0) {
      throw new Error('Invalid nodePath provided.');
    }

    this.scriptPosition = {
      nodePath,
      //
      nodeHeight,
      nodeChildrenTop,
      nodeChildrenHeight,
      //
      position,
    };
  }
}

// RECURSIVE METHOD
const evaluateNodesState = (editor: PrompterEditor, viewportInfo: IViewportInfo, parentNodePath: Path, scriptNodes: Descendant[], nodesMeta: ISlateNodeMeta[], nodesState: IScriptNodeState[] | undefined, prompterMode: PrompterMode, resultsCollectorInstance?: EvaluateNodesStateResultsClass): EvaluateNodesStateResultsClass => {
  const resultsCollector = resultsCollectorInstance || new EvaluateNodesStateResultsClass();

  const newScriptNodesState = nodesMeta.map((currentNodeMeta, index, array): IScriptNodeState => {
    const currentNodePath = [...parentNodePath, index];
    const prompterDescendant = scriptNodes[index];

    const prompterElement = prompterDescendant as PrompterElement;
    const isInline = Text.isText(prompterDescendant) || Editor.isInline(editor, prompterDescendant);

    // Copy some meta info
    const currentNodeHeight = currentNodeMeta.height;

    // Recalculate Node position
    const previousNodeState = nodesState && (array.length === nodesState.length) ? nodesState[index] : undefined;

    const segmentTopIntersection = Math.max(viewportInfo.viewportScrollTop, currentNodeMeta.top);
    const segmentBottomIntersection = Math.min(viewportInfo.viewportScrollBottom, currentNodeMeta.bottom);

    // True if any portion of this segment is visible in the viewport.
    const elementVisible = (segmentTopIntersection <= segmentBottomIntersection);

    // True if the entire node is above the viewport top.
    const elementAboveViewportTop = !elementVisible && (currentNodeMeta.bottom < viewportInfo.viewportScrollTop);

    // True if any portion of this segment is above the top edge of the viewport.
    const elementCrossingViewportTop = elementVisible && (currentNodeMeta.top < viewportInfo.viewportScrollTop);

    // True if any portion of this segment is at the cue position.
    const onCue = (segmentTopIntersection <= viewportInfo.viewportScrollCuePosition && segmentBottomIntersection >= viewportInfo.viewportScrollCuePosition);

    // Calculate the percentage through this element's height the cue position is.
    const anyPortionIsPassedCue = (Math.round(viewportInfo.viewportScrollCuePosition) >= Math.round(currentNodeMeta.top));
    const completelyPassedCue = (currentNodeMeta.bottom < viewportInfo.viewportScrollCuePosition);

    // The number of pixels of the current node's height remaining past the cue position.
    const heightRemaining = completelyPassedCue ? 0 : currentNodeMeta.bottom - viewportInfo.viewportScrollCuePosition;

    // We will hold a reference to the last block element in the script that has any portion
    // passed the cue position. This will be called our "current node" and the current script
    // position can be expressed as the percentage of this node's height that is passed the
    // cue position.
    if(
      anyPortionIsPassedCue
      && !(currentNodePath.length > 1 && completelyPassedCue) // Don't use nested blocked level elements that are fully passed the cue, instead fallback the last prompter segment root node.
      && !isInline
    ) {
      // if(prompterElement.type === ElementTypes.SCRIPT_SEGMENT && !completelyPassedCue) {
      //   const scalingHeight = currentNodeMeta.height - currentNodeMeta.childrenHeight;
      //   console.log(`PrompterSegment${currentNodePath} ScalingHeight: ${scalingHeight} = OuterHeight:${currentNodeHeight} - InnerHeight: ${currentNodeMeta.childrenHeight}`);
      // }

      // New way of collecting current node path and percentage passed cue.
      // console.log(`setCurrentNode<${prompterElement.type}>(currentNodePath:${currentNodePath}, nodeHeight:${currentNodeHeight}`);

      const position = viewportInfo.viewportScrollCuePosition - currentNodeMeta.top;
      resultsCollector.setCurrentNode(
        currentNodePath,
        currentNodeHeight,
        currentNodeMeta.childrenTop - currentNodeMeta.top,
        currentNodeMeta.childrenHeight,
        position
      );
    }

    // True if the entire node is above the cue position.
    const aboveCue = !onCue && (currentNodeMeta.bottom < viewportInfo.viewportScrollCuePosition);

    // True if the entire node is below the cue position.
    const belowCue = !onCue && (currentNodeMeta.top > viewportInfo.viewportScrollCuePosition);

    // True if any portion of this segment is below the bottom edge of the viewport.
    const elementCrossingViewportBottom = elementVisible && (currentNodeMeta.bottom > viewportInfo.viewportScrollBottom);

    // True if the entire node is below the viewport bottom
    const elementBelowViewportBottom = !elementVisible && (currentNodeMeta.top > viewportInfo.viewportScrollBottom);

    //
    // Assemble the NodeState for the current node.
    //
    const currentNodeState: IScriptNodeState = {
      visible: elementVisible,
      aboveViewport: elementAboveViewportTop,
      topClipped: elementCrossingViewportTop,
      aboveCue,
      onCue,
      heightRemaining,
      belowCue,
      bottomClipped: elementCrossingViewportBottom,
      belowViewport: elementBelowViewportBottom,
      children: [], //TODO: recursively check children positions within prompter segments
    };

    //
    // Recursively update child nodes' state if this parent node state changed (to avoid excessive
    // calculations during animation - we really only care about the nodes in the viewport).
    //
    if(prompterElement?.children && (!previousNodeState // If there was no previous node state this is the first run after loading script - calculate everything!
      || elementVisible || elementVisible !== previousNodeState.visible))
    {
      // Calculate current state for child nodes.
      const childrenNodesStateResults = evaluateNodesState(editor, viewportInfo, currentNodePath, prompterElement.children, currentNodeMeta.children, previousNodeState?.children, prompterMode, resultsCollector);
      // console.log('Children Node State', childrenNodesState);
      // const childrenHeight = childrenNodesStateResults.nodeStates[0].height
      currentNodeState.children = childrenNodesStateResults.nodeStates;
    }

    const prompterElementType = prompterElement?.type || 'leaf';
    const shouldLogEvent = prompterElement?.type !== undefined;

    //
    // 1. Check if this node is entering the viewport.
    // Event: prompter.node.{nodeType}.enteringviewport
    //
    const enteringviewport = (elementVisible && (previousNodeState && !previousNodeState.visible));
    if(enteringviewport) {
      // const reverse = previousNodeState.aboveViewport || previousNodeState.topClipped;

      const nodeEvent = ScriptNodeStateChangedEvent.build(
        ScriptNodeStateChangeTypes.ENTERING_VIEWPORT,
        prompterElement,
        currentNodePath,
        currentNodeMeta,
        previousNodeState,
        currentNodeState,
        viewportInfo,
        prompterMode
      );
      // if(shouldLogEvent) {
      //   console.log(`${prompterElementType} enteringviewport [${currentNodePath}] from ${reverse ? 'top of viewport' : 'bottom of viewport'}`);
      // }
      resultsCollector.addEvent(nodeEvent);
    }

    //
    // 1.b. Check if this node has entered the viewport.
    // Event: prompter.node.{nodeType}.enteredviewport
    //
    const enteredviewport = (elementVisible && (previousNodeState && previousNodeState.bottomClipped) && !elementCrossingViewportBottom);
    if(enteredviewport) {
      // const reverse = previousNodeState.aboveViewport || previousNodeState.topClipped;

      const nodeEvent = ScriptNodeStateChangedEvent.build(
        ScriptNodeStateChangeTypes.ENTERED_VIEWPORT,
        prompterElement, currentNodePath,
        currentNodeMeta, previousNodeState,
        currentNodeState,
        viewportInfo,
        prompterMode
      );
      // if(shouldLogEvent) {
      //   console.log(`${prompterElement.type} enteredviewport [${currentNodePath}] from ${reverse ? 'top of viewport' : 'bottom of viewport'}`);
      // }
      resultsCollector.addEvent(nodeEvent);
    }

    //
    // 2. Check if this node is entering the cue position.
    // Event: prompter.node.{nodeType}.enteringcue
    //
    const enteringcue = (onCue && (previousNodeState && !previousNodeState.onCue));
    if(enteringcue) {
      // const reverse = previousNodeState.aboveCue;

      const nodeEvent = ScriptNodeStateChangedEvent.build(
        ScriptNodeStateChangeTypes.ENTERING_CUE,
        prompterElement,
        currentNodePath,
        currentNodeMeta,
        previousNodeState,
        currentNodeState,
        viewportInfo,
        prompterMode
      );
      // if(shouldLogEvent) {
      //   console.log(`${prompterElementType} enteringcue [${currentNodePath}] from ${reverse ? 'above cue' : 'below cue'}`);
      // }
      resultsCollector.addEvent(nodeEvent);
    }

    //
    // 3. Check if this node is leaving the cue position.
    // Event: prompter.node.{nodeType}.leavingcue
    //
    const leavingcue = (!onCue && (previousNodeState && previousNodeState.onCue));
    if(leavingcue) {
      // const reverse = belowCue;

      const nodeEvent = ScriptNodeStateChangedEvent.build(
        ScriptNodeStateChangeTypes.LEAVING_CUE,
        prompterElement,
        currentNodePath,
        currentNodeMeta,
        previousNodeState,
        currentNodeState,
        viewportInfo,
        prompterMode
      );
      // if(shouldLogEvent) {
      //   console.log(`${prompterElementType} leavingcue [${currentNodePath}] towards ${reverse ? 'below cue' : 'above cue'}`);
      // }
      resultsCollector.addEvent(nodeEvent);
    }

    //
    // 4. Check if this node is "leaving" the viewport. Leaving means any portion of this node
    // Event: prompter.node.{nodeType}.leavingviewport
    //
    const leavingviewport = (elementCrossingViewportTop && (previousNodeState && !previousNodeState.topClipped));
    if(leavingviewport) {
      // const reverse = previousNodeState.bottomClipped;

      const nodeEvent = ScriptNodeStateChangedEvent.build(
        ScriptNodeStateChangeTypes.LEAVING_VIEWPORT,
        prompterElement,
        currentNodePath,
        currentNodeMeta,
        previousNodeState,
        currentNodeState,
        viewportInfo,
        prompterMode
      );
      // if(shouldLogEvent) {
      //   console.log(`${prompterElementType} leavingviewport [${currentNodePath}] through ${reverse ? 'bottom of viewport' : 'top of viewport'}`);
      // }
      resultsCollector.addEvent(nodeEvent);
    }

    // const leftviewport = (!elementVisible && (previousNodeState && previousNodeState.visible));

    //
    // Handle PrompterSegments
    //
    /*
    const prompterElementType = prompterElement?.type || 'leaf';
    if(previousNodeState && prompterElementType === ElementTypes.SCRIPT_SEGMENT && prompterMode === PrompterMode.Playing) {
      //
      // If this segment was onCue and just left the cue position - consider pausing for ShotLogging.
      //
      if(!onCue && previousNodeState.onCue !== onCue) {
        resultsCollector.bus.emit('prompter.state.pause');
        resultsCollector.bus.emit('prompter.delay.actions');
        resultsCollector.bus.emit('prompter.prompt.shotlog');
      }
    } // END handle PrompterSegment node position changes
    */

    return currentNodeState;
  }); // END map()

  resultsCollector.nodeStates = newScriptNodesState;

  return resultsCollector;
};  // END evaluateNodesState() function

/**
 * Hook monitors changes in content size and subsequently measures the script content for use in
 * detecting script element positions (when script element enter the viewport, pass the cue
 * position or leave the viewport) and translating scrolling speed from a connected peer
 * prompter.
 *
 * The prompter content can change size for many reasons including loading a new script, resizing
 * the browser window, or changing the prompter appearance parameters such as text size or
 * margins.
 *
 * @param appController
 * @param editor
 * @param prompterContentRef
 * @returns
 */
function usePrompterContentMeasurements(
  appController: AppController,
  editor: PrompterEditor,
  prompterContentRef: React.RefObject<HTMLDivElement>,
  cacheCurrentScriptPosition: CallableFunction,
  queueRestoreScriptPosition: CallableFunction,
) {

  // useWhatChanged([viewportRefs.previousLedgerRef, prompterSession.prompterMode, prompterSession.segments, configStore.cuePositionPercentage, configStore.flipVertical]);

  /**
   * Measure the script content DOM nodes, results in PrompterSession.setScriptNodesMeta().
   */
  const doMeasureScriptNodes = useCallback((): IPrompterMeta => {
    //
    // Whenever the prompter is resized in anyway, the max scroll position or number of lines of
    // text may change as a result of the resizing.
    //
    const {
      flipVertical,
    } = useConfigurationStore.getState();

    const {
      isEditing,
      scriptNodes,
      setScriptNodesMeta,
    } = usePrompterSession.getState();

    //
    // prompterContentRef wraps the slate Editor instance.
    //
    const prompterContentEl = prompterContentRef.current;
    if(!prompterContentEl) {
      //
      // It should be impossible to get here as doMeasureScriptNodes() is fired from a resize
      // observer, the resize observer cannot monitor the size of a ref until it is defined.
      // But we want type safety here and quick failure if some future bad code calls this in the
      // wrong place.
      //
      throw new Error('prompterContentRef not set');
    }
    const prompterContentRect = prompterContentEl.getBoundingClientRect();
    const contentHeight = prompterContentEl.offsetHeight;
    const contentWidth = prompterContentEl.offsetWidth;
    // console.log(`prompterContentRect.top: ${prompterContentRect.top}`, prompterContentRect);

    const computedStyle = window.getComputedStyle(prompterContentEl);
    const lineHeight = parseInt(computedStyle.lineHeight);
    const contentPaddingLeft = parseInt(computedStyle.paddingLeft);
    const contentPaddingRight = parseInt(computedStyle.paddingRight);


    //
    // Recursively measure all scriptNodes in the prompter. This will later be used for detecting
    // the position of elements and when they enter the viewport, enter or leave the cue position,
    // and leave the viewport.
    //
    // These events may be used to trigger automations or special actions when certain nodes reach
    // a trigger position.
    //
    const nodesMeta: ISlateNodeMeta[] = measureScriptNodes([], scriptNodes, editor, prompterContentRect, flipVertical);

    //
    // Store our current script meta date (measurements) in react state for future reference.
    //
    const prompterMeta: IPrompterMeta = {
      contentWidth,
      contentHeight,
      contentPaddingLeft,
      contentPaddingRight,
      lineHeight,
      nodesMeta
    };
    // console.log('Prompter Meta:', prompterMeta);
    setScriptNodesMeta(prompterMeta);

    //
    // Whenever the content size is changed we either need to either:
    // - inform other peers to recalculate their scroll position & speed (if we are leader)
    // - or recalculate our own scroll position & speed (if we are not leader)
    //
    const localPrompter = appController.deviceHost.getPrompterPeerLocalInstance();
    if(!localPrompter) {
      // LocalPrompter will not yet be defined very early in the page lifecycle.
      // When the page first load, we will want to measure the current script nodes which may
      // happen before we create the PrompterPeerInstance representing this peer.
      //
      // In this case, there is no need to to try and synchronize scroll speed or script
      // position at this time. We will synchronize when the first peer connection is made later.
      return prompterMeta;
    }

    //
    // If the local prompter is the current leader, then changes in our content size should trigger
    // all followers to recalculate their scroll position/speed.
    //
    if(!isEditing && localPrompter.isLeader) {
      const { scrollSpeed } = useConfigurationStore.getState();
      appController.dispatchMessage(new SetScrollSpeedMessage(scrollSpeed));
    }

    //
    // If the local prompter is not the current leader, then we want to sync our scroll position
    // and speed based on the current leader's meta info.
    //
    if(!localPrompter.isLeader) {
      const leaderPrompter = appController.deviceHost.getPrompterPeerLeader();
      if(localPrompter === leaderPrompter) {
        // console.log('localPrompter === leaderPrompter');
        return prompterMeta;
      }

      //
      // If we don't have recent meta data about the current leader, including viewport size, then
      // there is no sense in trying to synchronize the scroll position or scroll speed.
      //
      if(!leaderPrompter) {
        throw new Error('leaderPrompter is undefined in doMeasureScriptNodes()');
      }
      if(!leaderPrompter.lastSenderInfo) {
        throw new Error('leaderPrompter.lastSenderInfo is undefined in doMeasureScriptNodes()');
      }

      //
      // Recalculate our current scoll position within current script.
      //
      const syncAdjustmentMessage = calculateScrollPositionAdjustment(leaderPrompter.lastSenderInfo);
      if(syncAdjustmentMessage) {
        appController.handleLocalMessage(syncAdjustmentMessage);
      }

      //
      // Recalculate our current scroll speed
      //
      calculateScrollSpeed(leaderPrompter.lastSenderInfo);
    }

    return prompterMeta;
  }, [prompterContentRef, editor]); // END doMeasureScriptNodes()

  //
  // The index of the current script position under the cue position. If no script segment is under
  // the cue position now, then this will be the index of the last segment to have been under the
  // cue position.
  //
  // This is used to highlight in a table of contents where you are within a script or agenda.
  //
  const currentSegmentIndexRef = useRef<number>(0);

  //
  // The script node path for the current Script Node (at any depth in the node tree).
  //
  const currentNodePathRef = useRef<string>('');

  /**
   * This function should calculate where each segment is given the current viewport dimensions/
   * cue position and results in PrompterSession.setScriptNodesState()
   */
  const doRecalculateSegmentPositions = useCallback((): IPrompterPosition | undefined => {
    // console.log('BEGIN doRecalculateSegmentPositions!');
    // Recalculate segment positions relative to prompter scroll position and dimensions.
    const {
      cuePositionPercentage,
      flipVertical,
    } = useConfigurationStore.getState();

    const {
      viewportMeta,
      scriptNodes,
      scriptNodesMeta,
      scriptNodesState,
      setScriptNodesState,
      prompterMode,
      isLeader,
    } = usePrompterSession.getState();

    //
    // If we don't have all the information we need to calculate viewport and node positions, return.
    // This is only in the very first frame or two of rendering the DOM.
    //
    if(!scriptNodesMeta || !scriptNodesMeta.contentHeight || !scriptNodesMeta.nodesMeta) {
      return;
    }

    //
    // Retrieve measurements of current viewport.
    //
    const viewportInfo = getViewportInfo(flipVertical, cuePositionPercentage, viewportMeta);
    // console.log('viewportInfo: ', viewportInfo);

    //
    // Recursively calculate the state of each script node.
    //
    // console.log('BEGIN evaluateNodesState');
    const scriptNodesStates = evaluateNodesState(
      editor,
      viewportInfo,
      [],
      scriptNodes,
      scriptNodesMeta.nodesMeta,
      scriptNodesState?.nodesState,
      prompterMode
    );
    // console.log('END evaluateNodesState', scriptNodesStates.scriptPosition);

    /*
     * This works but can be cleaned up into a reusable utility function and maybe a method on the
     * state store.
     *
    //
    // Get relative position of the prompter viewport as measured from the top of the current node
    // as a percentage of the current node's content height.
    //
    */
    const { scriptPosition } = scriptNodesStates;
    if(!scriptPosition) {
      // throw new Error('No ScriptPosition returned while calculating.');
      return;
    }

    //
    // If the current script segment (root node) has changed, dispatch an app message to let other
    // components know.
    //
    const currentSegmentIndex = scriptPosition.nodePath[0];
    if(currentSegmentIndexRef.current !== currentSegmentIndex) {
      //
      // Current Script Segment has changed (root node). This event triggers updates to things like
      // the table of contents, highlighting the segment currently under the cue position.
      //
      scriptNodesStates.addEvent(new SegmentChangedMessage(currentSegmentIndex));

      currentSegmentIndexRef.current = currentSegmentIndex;
    }

    //
    // If the current script node (deepest block node) has changed, dispatch an app message to let
    // other components know.
    //
    const currentNodePath = scriptPosition.nodePath.join('-');
    if(
      currentNodePathRef.current !== currentNodePath
      && isLeader
    ) {
      //
      // Current Script Node (at any depth in the node tree) has changed.
      //
      // But, we only want to fire the NodeChangedMessage after initial render (before
      // initial render currentNodePathRef.current will be an empty string).
      //
      if(currentNodePathRef.current) {
        scriptNodesStates.addEvent(
          new NodeChangedMessage(
            scriptPosition.nodePath,
            scriptPosition.nodeHeight,
            scriptPosition.nodeChildrenTop,
            scriptPosition.nodeChildrenHeight,
            scriptPosition.position
          )
        );
      }

      currentNodePathRef.current = currentNodePath;
    }

    //
    // Save our current node state! We want to do this before we call
    // appController.dispatchMessage() below so that the node state is available for decorating
    // app messages with current state information.
    //
    // We only want to setScriptNodesState() when some nodeEvents were emitted!
    // If we did not have any nodeEvents emitted, then no state changed and we want to avoid unnecessary changes.
    //
    const results: IPrompterPosition = {
      cuePosition: viewportInfo.viewportScrollCuePosition,
      scriptPosition,
      // nodesState: (nodeEvents?.length) ? scriptNodesStates.nodeStates : scriptNodesState?.nodesState || scriptNodesStates.nodeStates,
      nodesState: scriptNodesStates.nodeStates,
    };
    setScriptNodesState(viewportInfo.viewportScrollTop, results);

    //
    // Dispatch any app messages that were created while evaluating any change in node states.
    //
    appController.dispatchMessages(scriptNodesStates.nodeEvents);

    // console.log('END doRecalculateSegmentPositions!', scriptNodesState);
    return;
  }, []); // END doRecalculateSegmentPositions()

  //
  // Anytime our prompter content or window is resized, we will want to measure our script nodes
  // and then update the script nodes state reflecting there they are (before, on, after cue
  // position, etc)
  //
  // Note: we want to be sure we finished measuring the script nodes, before we begin checking
  // their positions (using their measurements).
  //
  const previousContentWidthRef = useRef<number | undefined>();
  const onPrompterContentResized = useCallback(debounce((/*e?: ResizeObserverEntry*/) => {
    //
    // Cache the previous prompter script position before the content was resized.
    // cacheCurrentScriptPosition('onPrompterContentResized');

    //
    // Measure the size of each script node.
    const prompterMeta = doMeasureScriptNodes();

    //
    // Recalculate segment positions relative to prompter scroll position and dimensions.
    doRecalculateSegmentPositions();

    //
    // When the prompter content width change, because the browser was horizontally resized,
    // restore the same content at the queue position from before resize.
    //
    // We want to ignore vertical resizing of prompter content as that can occur when the
    // visualviewport is resized on iOS when the user scrolls up or down and Safari shows/hides the
    // browser UI toolbar. If want want to restore the scroll position for vertical resizing, it
    // should be done by watching for vertical resize on the browser window (not the visualviewport
    // or the prompter content).
    //
    if(previousContentWidthRef.current && previousContentWidthRef.current !== prompterMeta.contentWidth) {
      // It is not the initial page load (because previousContentWidthRef.current has a value) and
      // our content width measurement has changed.
      queueRestoreScriptPosition();
    }

    previousContentWidthRef.current = prompterMeta.contentWidth;
  }, 350, { leading: false, trailing: true}), [cacheCurrentScriptPosition, doMeasureScriptNodes, doRecalculateSegmentPositions, previousContentWidthRef, queueRestoreScriptPosition]);  // END onPrompterContentResized()

  //
  // When our prompter content <div> element is resized (ie: changed the font size, margins, or
  // prompter content).
  //
  useResizeObserver(prompterContentRef, onPrompterContentResized);

  //
  // When certain prompter elements are re-sized or re-rendered, they may request the script DOM be measured.
  //
  useMessageHandler('measuredom', () => {
    // console.log('measuredom requested');
    onPrompterContentResized();
  });

  //
  // When any state changes that might affect our Viewport layout, we need to recalculate our
  // IViewportMeta.
  //
  const configStore = useConfigurationStore(state => ({
    cuePositionPercentage: state.cuePositionPercentage,
    mediaPanelPosition: state.mediaPanelPosition,
    mediaPanelHeight: state.mediaPanelHeight,
  }), shallow);
  const {
    cuePositionPercentage,
    mediaPanelPosition,
    mediaPanelHeight,
  } = configStore;
  const {
    viewportWidth,
    viewportHeight,
    viewportMeta,
    setViewportMeta,
    mediaSourceType: media1SourceType,
  } = usePrompterSession.getState();
  useEffect(() => {

    const currentViewportWidthPx = viewportWidth || window.document.scrollingElement?.clientWidth || 0;
    const currentViewportHeightPx = viewportHeight || window.document.scrollingElement?.clientHeight || 0;

    //
    // If the MediaPanel is open, and it positioned at the top of the viewport, then we need to
    // adjust our top margin for the StartElement to reflect the space.
    //
    const mediaPanelIsOpen = media1SourceType !== MediaSourceType.None;

    //
    // This is the logical space taken up by the Media Panel, if open and positioned at the top of
    // the viewport, between the logical top of the viewport and the cue position. Note, this does
    // not change when the screen if flipped, the logical position remains the same.
    //
    const viewportMarginTopPx = mediaPanelIsOpen
      && mediaPanelPosition === MediaPanelPosition.Top
      ? Math.round(currentViewportHeightPx * mediaPanelHeight / 100)
      : 0;
    const viewportMarginBottomPx = mediaPanelIsOpen
      && mediaPanelPosition === MediaPanelPosition.Bottom
      ? Math.round(currentViewportHeightPx * mediaPanelHeight / 100)
      : 0;

    //
    // This is the remaining portion of the viewport available to read the script after accounting
    // for any space taken up by UI elements like the MediaPanel.
    //
    const availableViewportHeightPx = currentViewportHeightPx - viewportMarginTopPx - viewportMarginBottomPx;

    const cuePositionPercentageOfHeight = cuePositionPercentage / 100;

    //
    // This is the logical distance between the logical top of the viewport and the cue position.
    //
    const cuePositionOffsetPx = viewportMarginTopPx
      + (availableViewportHeightPx * cuePositionPercentageOfHeight);

    //
    // How much space should be added before the start of the script and after the end of the
    // script? This works correctly whether flipped or not flipped!
    //
    const scriptMarginStart = (availableViewportHeightPx * cuePositionPercentageOfHeight) + viewportMarginTopPx;
    const scriptMarginEnd = (availableViewportHeightPx * (1 - cuePositionPercentageOfHeight)) + viewportMarginBottomPx;

    //
    // Assemble our IViewportMeta data that we will save in our prompter session state.
    //
    const proposedViewportMeta: IViewportMeta = {
      viewportWidth: currentViewportWidthPx,
      viewportHeight: currentViewportHeightPx,
      viewportMarginTop: viewportMarginTopPx,
      viewportMarginBottom: viewportMarginBottomPx,
      availableViewportHeight: availableViewportHeightPx,
      cuePositionOffset: cuePositionOffsetPx,
      //
      scriptMarginStart,
      scriptMarginEnd,
    };

    // Only update our state store if the IViewportMeta has actually changed.
    const viewportMetaIsSame = shallow(viewportMeta, proposedViewportMeta);
    if(!viewportMetaIsSame) {
      // console.log('useEffect() - RECALCULATE IViewportMeta', proposedViewportMeta);
      setViewportMeta(proposedViewportMeta);
    }
  }, [
    viewportWidth,
    viewportHeight,
    viewportMeta,
    setViewportMeta,
    onPrompterContentResized,
    media1SourceType,   // If media source is changed, measure script nodes and recalculate their relative positions.
    cuePositionPercentage,
    mediaPanelPosition, // If the media panel position is changed, measure script nodes and recalculate their relative positions.
    mediaPanelHeight,   // If the media panel height is changed, measure script nodes and recalculate their relative positions.
  ]);

  //
  // When the browser widnow is scrolled (for any reason: automated or user initiated), we want to
  // recalculate the prompter script positions
  //
  const onPrompterScrolled = useCallback(() => {
    doRecalculateSegmentPositions();
  }, [doRecalculateSegmentPositions]);

  //
  // When the browser window is resized.
  //
  const onWindowResized = useCallback(debounce(() => {
    const scrollerElement = document.scrollingElement;
    if(!scrollerElement) {
      return;
    }

    //
    // Update our application viewport dimensions. This will trigger a recalculation of the virtual
    // viewport taking into account any viewport margins that are being applied due to the
    // visibility of a MediaPanel or similar obstruction.
    //
    usePrompterSession.getState().setViewportDimensions(
      scrollerElement.clientWidth,
      scrollerElement.clientHeight,
    );
  }, 1000 / 30, { leading: false, trailing: true}), []);
  useEffect(() => {
    window.addEventListener('scroll', onPrompterScrolled);
    window.addEventListener('resize', onWindowResized);

    return () => {
      window.removeEventListener('scroll', onPrompterScrolled);
      window.removeEventListener('resize', onWindowResized);
    };
  }, [onWindowResized]);

  //
  // If the user changes the position of the focus guide, we need to recalculate our script nodes
  // positions.
  //
  useEffect(() => {
    const unsubscribe = useConfigurationStore.subscribe(
      (config, previousConfig) => {
        // console.log('PrompterConfig changed.')
        if(config.cuePositionPercentage !== previousConfig.cuePositionPercentage) {
          // console.log('PrompterConfig.cuePositionPercentage changed.')
          //
          // The focus guide CSS animates changes in position over 500ms. We don't want to
          // recalculate our segment positions until the animation is done.
          //
          // This delay may not actually be necessary if we already know where the focus guide will
          // be.
          //
          window.setTimeout(doRecalculateSegmentPositions, 550);
        }
      }
    );

    return unsubscribe;
  }, [doRecalculateSegmentPositions]);

  //
  // Export any functions from this hook that may be used elsewhere in the editor logic such as
  // when opening or editing a script.
  //
  return {
    /**
     * Measure the script content DOM nodes, results in PrompterSession.setScriptNodesMeta().
     */
    doMeasureScriptNodes,

    /**
     * This function should calculate where each segment is given the current viewport dimensions/
     * cue position and results in PrompterSession.setScriptNodesState()
     */
    doRecalculateSegmentPositions,
  };
}

export default usePrompterContentMeasurements;