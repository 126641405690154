import { BaseControlMessage, EndpointRole } from '@fluidprompter/core';
import AppController from './AppController';

import calculateScrollPositionAdjustment from '../../utils/calculateScrollPositionAdjustment';
import calculateScrollSpeed from '../../utils/calculateScrollSpeed';

/**
 * Messages will be wrapped in a MessageContext before firing all handlers.
 * The context will cache certain data
 */
export class MessageContext
{
  constructor(appController: AppController, batch: BaseControlMessage[], originatedRemotely?: boolean, remoteSource?: string) {
    this._controller = appController;
    this._batch = batch;
    this._currentIndex = 0;
    this._originatedRemotely = (originatedRemotely === true);
    this.remoteSource = remoteSource;
    this._sendToPeers = false;
  }

  [Symbol.iterator](): Iterator<BaseControlMessage> {
    this._currentIndex = -1;

    return {
      next: (): IteratorResult<BaseControlMessage, undefined> => {
        this._currentIndex++;
        if(this._currentIndex >= this._batch.length) {
          return { value: undefined, done: true };
        }

        return { value: this._batch[this._currentIndex], done: false };
      }
    };
  }

  /**
   * If this context is still being processed, the current messages will be added to the current
   * batch to be processed at the same time.
   *
   * If this context has finished being processed, then we will dispatch the new messages via the
   * appController.
   *
   * @param messageBatch An array of messages to dispatch for processing and possible transmission to peers.
   * @returns
   */
  dispatchMessages(messageBatch: BaseControlMessage[]) {
    // console.log(`Message handler produced ${messageBatch.length} new message(s):`, messageBatch);

    // TODO: Need to unit test this.
    if(this._currentIndex >= (this._batch.length - 1)) {
      // This context was already full processed! Need to dispatch under a new context...
      this.appController.dispatchMessages(messageBatch);
      return;
    }

    // Insert the new messages into the current batch right after the current message.
    if(this._currentIndex === this._batch.length - 1) {
      // append
      this._batch.push(...messageBatch);
    } else {
      // insert (you cannot insert at the end of the array, must be before an indexable element in the array)
      this._batch.splice(this._currentIndex + 1, 0, ...messageBatch);
    }
  }

  /**
   * Returns an Iterator for only those messages in this batch that should be sent to connected
   * peers via RTCDataChannel or WebSocket.
   * @returns
   */
  messagesToTransmit(): BaseControlMessage[] {
    return this._batch.filter((message) => message.sendToPeers === true);
  }

  private get appController() {
    return this._controller;
  }
  private _controller: AppController;

  // public get batch() {
  //   return this._batch;
  // }
  private _batch: BaseControlMessage[];

  // public get currentIndex() {
  //   return this._currentIndex;
  // }
  private _currentIndex: number;

  public get message() {
    return this._batch[this._currentIndex];
  }

  public get originatedRemotely() {
    return this._originatedRemotely;
  }
  private _originatedRemotely: boolean;

  public remoteSource?: string;

  public get sendToPeers() {
    return this._sendToPeers === true;
  }
  public set sendToPeers(value: boolean) {
    this._sendToPeers = value;
  }
  private _sendToPeers: boolean;

  setLeaderEndpointId(leaderId: string) {
    this.appController.deviceHost.setLeaderEndpointId(leaderId, {
      reason: `MessageContext.setLeaderEndpointId(${leaderId})`,
    });
  }

  syncScrollPosition() {
    const { sender } = this.message;
    if(!sender || sender.role === EndpointRole.Remote) {
      // `senderState` is optional and not included in certain app messages - mostly messages that
      // do not affect the prompter position/state.
      //
      // Also we never want to sync the scroll position from a connected cloud remote.
      return;
    }

    const syncAdjustmentMessage = calculateScrollPositionAdjustment(sender);
    if(syncAdjustmentMessage) {
      this.appController.handleLocalMessage(syncAdjustmentMessage);
    }
  }

  syncScrollSpeed() {
    const { sender } = this.message;
    if(!sender) {
      return;
    }

    calculateScrollSpeed(sender);
  }
}