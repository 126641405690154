import React from 'react';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import NoticeBar from './NoticeBar';

import useApplicationContext from '../../hooks/useApplicationContext';
import { Trans, useTranslation } from 'react-i18next';

interface NoticeSubscriptionCancelledProps {
  cancellation_requested?: boolean;
  requestClose?: () => void;
}

function NoticeSubscriptionCancelled(props: NoticeSubscriptionCancelledProps) {
  const { cancellation_requested, requestClose } = props;

  const { t } = useTranslation('prompter');

  const {
    subscription,
  } = useApplicationContext(true);

  let noticeMsg = t('noticebars.subscription_cancelled.payment_failed');
  if(cancellation_requested) {
    noticeMsg = t('noticebars.subscription_cancelled.cancellation_requested');
  }

  return (
    <NoticeBar
      type='warning'
      startIcon={<ErrorOutlineIcon /*color='warning'*/ style={{ color: '#b6b600' }} />}
      ctaShortCaption={t('noticebars.subscription_cancelled.cta_short')}
      ctaCaption={t('noticebars.subscription_cancelled.cta_full')}
      ctaAction={() => {
        subscription.redirectToBillingPortal();
      }}
      requestClose={requestClose}
    >{noticeMsg}</NoticeBar>
  );
}

export default NoticeSubscriptionCancelled;