import React, { useEffect, useCallback, useRef, RefObject } from 'react';
import { IScriptPosition } from '@fluidprompter/core';
import { IViewportFuncs } from '../PrompterViewport/usePrompterViewportFuncs';

import usePrompterSession from '../../state/PrompterSessionState';
import debounce from 'lodash/debounce';

export interface PrompterViewportProps {
  textSize: number;
  lineHeight: number;
  textColor: string;
  contentWidth: number;
  leftGutter: number;
  rightGutter: number;
  flipHorizontal: boolean;
  flipVertical: boolean;
}

function usePrompterCachePosition(props: PrompterViewportProps, viewportFuncs: IViewportFuncs, prompterContentRef: RefObject<HTMLDivElement>) {
  const {
    textSize,
    lineHeight,
    contentWidth,
    leftGutter,
    flipVertical,
  } = props;

  //
  // Whenever the component is re-rendered with a new CSS class, add a listener for any CSS
  // animations that complete. When certain animations end, we will change the CSS class.
  //
  const positionBeforeTransition = useRef<IScriptPosition>();
  const restorePositionBeforeTransitionTimeout = useRef<number>();
  const clearPositionBeforeTransitionTimeout = useRef<number>();
  const cacheCurrentScriptPosition = useCallback((reason?: string) => {
    //
    // Set or Reset our timer to clear the cached queued position. Effectively we are debouncing
    // the window in which the cached script position stays the same.
    //
    // For example if we received 50 window resized events in a short period of time, we want to
    // cache the script position during the first event, and maintain that cached position until
    // 1 second after the last window resized event.
    //
    clearTimeout(clearPositionBeforeTransitionTimeout.current);
    clearPositionBeforeTransitionTimeout.current = window.setTimeout(() => {
      positionBeforeTransition.current = undefined;
    }, 1000);

    //
    // If we already have a cached script position, we don't want to override it.
    //
    if(positionBeforeTransition.current) {
      return;
    }

    //
    // Save our script position before the change in flip state has been applied to the DOM.
    //
    const { scriptNodesState } = usePrompterSession.getState();
    positionBeforeTransition.current = scriptNodesState?.scriptPosition;
  }, [clearPositionBeforeTransitionTimeout, positionBeforeTransition]);
  const queueRestoreScriptPosition = useCallback(() => {
    //
    // If CSS `transitionend` event is fired for property `transform` then we just finished
    // visually transition to the current transform attributes.
    //
    // This will happen when the user changes the flipHorizontal or flipVertical configurations.
    //
    // After the prompter content size changed, let's restore the same content at the cue
    // position as was before the change.
    //
    clearTimeout(restorePositionBeforeTransitionTimeout.current);
    restorePositionBeforeTransitionTimeout.current = window.setTimeout(() => {
      if(positionBeforeTransition.current) {
        const {
          viewportMeta,
          getScrollPositionByScriptPosition,
        } = usePrompterSession.getState();

        const targetPosition = getScrollPositionByScriptPosition(positionBeforeTransition.current);
        if(!targetPosition) {
          console.error('queueRestoreScriptPosition() getScrollPositionByScriptPosition() returned undefined', positionBeforeTransition.current);
          return;
        }

        viewportFuncs.scrollToPosition({
          scrollPosition: targetPosition.scrollPosition - viewportMeta.cuePositionOffset,
          scrollBehavior: 'smooth',
          scrollTime: 350,
        });
      }
    }, 100);
  }, [restorePositionBeforeTransitionTimeout, positionBeforeTransition, viewportFuncs]);

  const lastFlipVerticalState = useRef<boolean | undefined>();
  useEffect(() => {
    if(!prompterContentRef.current) {
      return;
    }

    if(lastFlipVerticalState.current !== undefined && lastFlipVerticalState.current !== flipVertical) {
      //
      // Cache the current script position before the script was flipped/unflipped.
      //
      cacheCurrentScriptPosition('flipVertical');

      //
      // If CSS `transitionend` event is fired for property `transform` then we just finished
      // visually transitioning to the current transform attributes.
      //
      // This will happen when the user changes the flipHorizontal or flipVertical configurations.
      //
      // After the prompter content size changed, let's restore the same content at the cue
      // position as was before the change.
      //
      prompterContentRef.current.addEventListener('transitionend', (e) => {
        if(e.propertyName !== 'transform') {
          return;
        }

        queueRestoreScriptPosition();
      }, { once: true });
    }

    lastFlipVerticalState.current = flipVertical;
  }, [
    prompterContentRef,
    positionBeforeTransition,
    restorePositionBeforeTransitionTimeout,
    clearPositionBeforeTransitionTimeout,
    flipVertical,
  ]); // Make sure the effect runs when `flipVertical` changes

  //
  // Restore our current script position whenever appearance configuration changes (content width,
  // text size, left gutter, line height).
  //
  const onNextPrompterContentRendered = useRef<CallableFunction>();
  const lastAppearanceConfigState = useRef({
    contentWidth,
    leftGutter,
    textSize,
    lineHeight,
  });
  useEffect(() => {
    if(!prompterContentRef.current) {
      return;
    }

    //
    // Ignore any re-render where the appearance configuration did not change.
    // This ignores the initial rendering of the page on first load.
    // We are only interested in event where the appearance configuration was changed after the
    // PrompterContent was already rendered.
    //
    const prevState = lastAppearanceConfigState.current;
    if(
      prevState.contentWidth === contentWidth
        && prevState.leftGutter === leftGutter
        && prevState.textSize === textSize
        && prevState.lineHeight === lineHeight
    ) {
      return;
    }
    lastAppearanceConfigState.current = {
      contentWidth,
      leftGutter,
      textSize,
      lineHeight,
    };

    //
    // Cache the current script position before the contentWidth, leftGutter, textSize, or
    // lineHeight configuration was changed.
    //
    cacheCurrentScriptPosition('contentWidth,leftGutter,textSize,lineHeight changed');

    //
    // When the prompter content is next rendered (because of the change in appearance
    // configuration) we want to restore the cached script position.
    //
    onNextPrompterContentRendered.current = queueRestoreScriptPosition;
  }, [
    prompterContentRef,
    lastAppearanceConfigState,
    cacheCurrentScriptPosition,
    onNextPrompterContentRendered,
    queueRestoreScriptPosition,
    contentWidth,
    leftGutter,
    textSize,
    lineHeight,
  ]); // Make sure the effect runs only once

  const onPrompterContentRendered = useCallback(() => {
    //
    // If we have a callback waiting for the prompter content to be re-rendered, lets execute that
    // callback now and then clear our reference to it.
    //
    const onNextPrompterContentRenderedCallback = onNextPrompterContentRendered.current;
    if(onNextPrompterContentRenderedCallback) {
      onNextPrompterContentRenderedCallback();

      onNextPrompterContentRendered.current = undefined;
    }
  }, [onNextPrompterContentRendered]);


  const lastVisualViewportWidth = useRef<number>(window.visualViewport?.width || 0);
  useEffect(() => {

    const listener = debounce((e: Event) => {
      const visualViewport = e.target as VisualViewport;

      //
      // Cache the current script position before a new script position is calculated for the new
      // visualViewport dimensions.
      //
      if(lastVisualViewportWidth.current !== visualViewport.width) {
        cacheCurrentScriptPosition('visualViewport.resize');
      }
      lastVisualViewportWidth.current = visualViewport.width;

    }, 250, { leading: true, trailing: false});

    if('visualViewport' in window && window.visualViewport) {  // Not supported in Unit Testing Environment with no real viewport.
      window.visualViewport.addEventListener('resize', listener);
    }

    return () => {
      if('visualViewport' in window && window.visualViewport) {  // Not supported in Unit Testing Environment with no real viewport.
        window.visualViewport.removeEventListener('resize', listener);
      }
    };
  }, []);

  return {
    cacheCurrentScriptPosition,
    queueRestoreScriptPosition,
    onPrompterContentRendered,
  };
}

export default usePrompterCachePosition;