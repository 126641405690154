import React from 'react';
import Collapse from '@mui/material/Collapse';

import Stack from '@mui/material/Stack';
// import Box from '@mui/material/Box';
import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';

import { useAppController } from '../../../controllers/AppController';
import { useTranslation } from 'react-i18next';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
import AddIcon from '@mui/icons-material/Add';
// import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import StarIcon from '@mui/icons-material/Star';

import { DeviceConnectionType, IDeviceState } from '../../../devices/BaseDevice';

// Potential TURN Relay Icons
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import RepeatIcon from '@mui/icons-material/Repeat';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import SyncIcon from '@mui/icons-material/Sync';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import RMobiledataIcon from '@mui/icons-material/RMobiledata';  // Tiny superscript R

import { Crown } from 'lucide-mui';

import usePrompterSession from '../../../state/PrompterSessionState';
import { ConnectionState } from '@fluidprompter/core';

import DeviceCountBadge from './DeviceCountBadge';
import DeviceAvatar from './DeviceAvatar';
import PeerDevice from './PeerDevice';

interface DevicesMenuProps {
  expanded: boolean;
  onExpand: () => void;
}
const DevicesMenu = (props: DevicesMenuProps) => {
  const { t } = useTranslation('appmenu');

  const appController = useAppController();

  const connectedDevices = usePrompterSession(state => state.connectedDevices);

  const [selectedIndex, setSelectedIndex] = React.useState<string | undefined>();

  const requestMenuIndex = (
    deviceId: string,
  ) => {
    if(selectedIndex === deviceId) {
      // We are clicking an item that is already selected.
      setSelectedIndex(undefined);
    } else {
      setSelectedIndex(deviceId);
    }
  };

  const handleConnectNewDevice = () => {
    appController.dispatchMessage('app.connect.device');
  };

  const renderDevices = (connectedDevices: IDeviceState[], depth = 0, parentId?: string): React.ReactNode => {
    const qualifiedDevices = connectedDevices.filter((device) => device.parentId === parentId);
    if(!qualifiedDevices.length) {
      return;
    }

    const renderedDevices = qualifiedDevices
      .sort((a: IDeviceState, b: IDeviceState) => {
        return (a.sortPriority === b.sortPriority) ? 0
          : (a.sortPriority < b.sortPriority) ? -1 : 1;
      })
      .map((connectedDevice, index, array) => {
        const hasChildren = (connectedDevices.findIndex((value) => value.parentId === connectedDevice.id) >= 0);

        return (
          <React.Fragment key={connectedDevice.id}>
            <PeerDevice
              depth={depth}
              device={connectedDevice}
              index={index}
              array={array}
              hasChildren={hasChildren}
              expanded={selectedIndex === connectedDevice.id}
              onExpand={() => { requestMenuIndex(connectedDevice.id); }}
            />
            {renderDevices(connectedDevices, depth + 1, connectedDevice.id)}
          </React.Fragment>
        );
      });

    if(depth === 0) {
      return renderedDevices;
    }

    return (<List disablePadding>
      {renderedDevices}
    </List>);
  };

  return (
    <>
      <ListItemButton
        selected={props.expanded}
        onClick={() => {
          props.onExpand();
        }}
      >
        <ListItemIcon><OndemandVideoIcon /></ListItemIcon>
        <ListItemText
          primary={<DeviceCountBadge badgeContent={connectedDevices.length} invisible={connectedDevices.length < 2}>{t('devicesmenu.caption')}</DeviceCountBadge>}
        />
        {props.expanded ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse
        sx={{
          /*pb: 2,*/
          background: 'rgba(25, 118, 210, 0.08)',
        }}
        in={props.expanded} timeout="auto" unmountOnExit
      >
        <List component="div" disablePadding /*sx={{
          ml: 2,
          background: 'rgba(25, 118, 210, 0.12)',
        }}*/>
          {renderDevices(connectedDevices)}

          <ListItemButton
            alignItems="flex-start"
            onClick={handleConnectNewDevice}
          >
            <ListItemText primary={<><AddIcon aria-hidden="false" role="img" sx={{ verticalAlign: 'middle' }} />{t('devicesmenu.connectanotherdevice')}</>} primaryTypographyProps={{
              color: '#1976DC', // #0000ff
              align: 'right',
              sx: { textDecoration: 'underline' },
            }} />
          </ListItemButton>
        </List>
      </Collapse>
    </>
  );
};

export default DevicesMenu;