import React, { useCallback, useEffect, useRef } from 'react';

const CONF_DOUBLE_CLICK_MS = 350; // 350 ms
const CONF_LONG_PRESS_MS = 500; // 500 ms

export interface LongTouchEvent {
  repeatCount: number;
}

interface GestureInfo {
  gesture: string;
  distanceMoved: number;
  scale: number;
  initialAngle?: number;
  currentAngle?: number;
}

//
// Pinch-zoom is not reliable, especially on Safari on iOS. However it may be more valuable in our
// native apps when released. For now we will feature flag it as disabled.
//
const PINCH_ZOOM_FEATURE_ENABLED = false;

const getViewportScale = () => {
  return window.visualViewport
    //
    // We round the visualViewport.scale value to 2 decimal places. Particularly on Firefox on
    // Android (Ben's Samsung A8) the browser never got to truly 1 scale, it was reporting a scale
    // of '1.000000564' which is effectively 1.
    ? Math.round(window.visualViewport.scale * 100) / 100
    //
    // If a browser doesn't support visualViewport it will be quite old, we'll just assume scale of
    // 1 which will disable our ability to know when the page was pinch-zoomed.
    : 1;
};

/**
 * Given a set of touches on the screen and the initial set of touches from the start of the
 * gesture start, figure out what gesture is in progress and return information about it.
 * @param currentTouches
 * @param initialTouches
 * @returns
 */
const calculateGesture = (currentTouches: TouchList, initialTouches?: TouchList): GestureInfo => {
  const results: GestureInfo = {
    gesture: 'unknown',
    distanceMoved: 0,
    scale: 1,
    initialAngle: undefined,
    currentAngle: undefined,
  };

  // If we have no initial touches, is this an error case?
  if(
    !initialTouches
      || initialTouches.length < 1
      || currentTouches.length < 1
  ) {
    return results;
  }
  const initialTouch1 = initialTouches[0];
  const currentTouch1 = currentTouches[0];

  //
  // If we have multiple active touches, then we have a pinch-zoom gesture happening.
  //
  if(PINCH_ZOOM_FEATURE_ENABLED && currentTouches.length > 1 && initialTouches.length > 1) {
    // We have a multi-touch gesture happening!
    // Let's process a pinch zoom.
    results.gesture = 'pinch';

    const initialTouch2 = initialTouches[1];
    const initialPinchDistance = Math.hypot(
      Math.abs(initialTouch2.screenX - initialTouch1.screenX), // Movement X
      Math.abs(initialTouch2.screenY - initialTouch1.screenY)  // Movement Y
    );

    results.initialAngle = Math.atan2(
      Math.abs(initialTouch2.screenY - initialTouch1.screenY),
      Math.abs(initialTouch2.screenX - initialTouch1.screenX)
    ) * 180 / Math.PI;

    const currentTouch2 = currentTouches[1];
    const currentPinchDistance = Math.hypot(
      Math.abs(currentTouch2.screenX - currentTouch1.screenX), // Movement X
      Math.abs(currentTouch2.screenY - currentTouch1.screenY)  // Movement Y
    );

    results.scale = currentPinchDistance / initialPinchDistance;
    return results;
  }

  //
  // Single finger touch - calculate the distance between touchstart and current touch location
  //
  // In a multitouch gesture this will current only look at the first point of touch. In future
  // we may change this algorithm to detect a change in distance between 2 touch points for
  // gestures (like pinch zoom).
  //
  if(initialTouch1 && currentTouch1) {
    results.gesture = 'touch';
    const distanceMoved = Math.hypot(
      Math.abs(currentTouch1.screenX - initialTouch1.screenX), // Movement X
      Math.abs(currentTouch1.screenY - initialTouch1.screenY)  // Movement Y
    );

    results.distanceMoved = distanceMoved;
    return results;
  }

  // Unkown or unhandled gesture
  return results;
};

interface ITouchableElement {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addEventListener<K extends keyof GlobalEventHandlersEventMap>(type: K, listener: (this: Document, ev: GlobalEventHandlersEventMap[K]) => any, options?: boolean | AddEventListenerOptions): void;
  addEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  removeEventListener<K extends keyof GlobalEventHandlersEventMap>(type: K, listener: (this: Document, ev: GlobalEventHandlersEventMap[K]) => any, options?: boolean | EventListenerOptions): void;
  removeEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | EventListenerOptions): void;
}
export interface GestureEvent {
  scale: number,
  angle: number,
}

interface useTouchInputProps {
  name: string;
  touchTarget: ITouchableElement;
  shouldIgnoreTouchTarget?: (e: TouchEvent) => boolean;
  touchStart?: (e: TouchEvent) => void;
  touchMove?: (e: TouchEvent) => void;
  touchEnd?: (e: TouchEvent) => void;
  touchedCallback?: () => void;
  doubleTouchedCallback?: () => void;
  longTouchedCallback?: (e: LongTouchEvent) => void;
  doubleLongTouchedCallback?: () => void;
  pinchZoom?: (e: GestureEvent) => void;
}

/**
 * This hook will attach event listeners on the specified `touchTarget` for touchstart,
 * touchmove, touchend in order to detect the following touch gestures: tapped,
 * double-tapped, long-tapped (with repeat), double-long-tapped (with repeat).
 * @param props
 * @returns
 */
function useTouchInput(props: useTouchInputProps) {
  const {
    name,
    touchTarget,
    shouldIgnoreTouchTarget,
    touchStart,
    touchMove,
    touchEnd,
    touchedCallback,
    doubleTouchedCallback,
    longTouchedCallback,
    doubleLongTouchedCallback,
    pinchZoom,
  } = props;

  const _lastTimeStamp = useRef<number>(0);
  const _multipressInProgress = useRef<boolean>(false);
  const _repeatCount = useRef<number>(0);
  const _pressEventTimer = useRef<number>(0);
  const _longpressTimer = useRef<number>(0);

  const activeTouchesCount = useRef(0);
  const initialTouches = useRef<TouchList>();
  const suppressTapEvents = useRef<boolean>(false);
  const viewportWasScaled = useRef<boolean>(false);

  const handleTouchStart = useCallback((e: TouchEvent) => {
    activeTouchesCount.current = e.touches.length;
    initialTouches.current = e.touches;
    const elapsed = Date.now() - _lastTimeStamp.current;

    const preventHandlingTouchEvent = shouldIgnoreTouchTarget?.call(touchTarget, e);
    if(preventHandlingTouchEvent) {
      // We should not handle this touch event because our `touchFilter` callback has classified
      // this touch as being intended for some other touch target like a `button`, `a`, or UI
      // outsite of `.prompterContent`.
      _lastTimeStamp.current = 0;
      return;
    }

    // Fire `touchStart` callback, if configured for this hook
    touchStart?.call(touchTarget, e);

    //
    // If we are starting a multi-touch gesture, then prevent the possibility for a touched,
    // double-touch, long-touched, double-long-touched event.
    //
    if(
      e.touches.length > 1
      || getViewportScale() > 1
    ) {
      // Multi-touch gesture. We don't want to fire events for touched, double-touched, or
      // long-touched.
      suppressTapEvents.current = true;

      // clearInterval(_longpressTimer.current);
      // _longpressTimer.current = 0;

      // // cancel press timer if we move the touch:
      // clearTimeout(_pressEventTimer.current);
      // _pressEventTimer.current = 0;
    }

    // If we are pressing this button sooner than
    // a queued "press event", then cancel the press
    // event as we are going to fire a "double click"
    // event instead
    if (elapsed < CONF_DOUBLE_CLICK_MS && _pressEventTimer.current) {
      clearTimeout(_pressEventTimer.current);
      _pressEventTimer.current = 0;

      _multipressInProgress.current = true;
    }

    // If we didn't suppressTapEvents above because of a multi-touch gesture or the visual viewport
    // being zoomed in, then we will start long-press timer.
    if(!suppressTapEvents.current) {
      _longpressTimer.current = window.setInterval(() => {
        if (_multipressInProgress.current) {
          // double-long-pressed
          // Fire `doubleLongTouchedCallback` callback, if configured for this hook
          doubleLongTouchedCallback?.call(touchTarget);
          _multipressInProgress.current = false;
        } else {
          _repeatCount.current += 1;
          if(_repeatCount.current === 1) {
            // First long press
            // Fire `longTouchedCallback` callback, if configured for this hook
            longTouchedCallback?.call(touchTarget, {
              repeatCount: _repeatCount.current,
            });
          } else {
            // 2nd or more long press
            // Fire `longTouchedCallback` callback, if configured for this hook
            longTouchedCallback?.call(touchTarget, {
              repeatCount: _repeatCount.current,
            });
          }

          if(_pressEventTimer.current) {
            clearTimeout(_pressEventTimer.current);
            _pressEventTimer.current = 0;
          }
        }
      }, CONF_LONG_PRESS_MS);
    }

    _lastTimeStamp.current = Date.now();
  }, [touchStart, longTouchedCallback, doubleLongTouchedCallback, activeTouchesCount, touchTarget, shouldIgnoreTouchTarget, _lastTimeStamp, _pressEventTimer, _multipressInProgress, _longpressTimer, _repeatCount]);

  const handleTouchMove = useCallback((e: TouchEvent) => {
    //
    // If the visualViewport.scale is > 1 it means the user has managed to perform browser natice
    // pinch-zoom gesture despite our best efforts to prevent it. In order to make sure they don't
    // get stuck zoomed in, we won't process any touch events until they return to standard zoom
    // scale of 1.
    //
    if(getViewportScale() > 1) {
      viewportWasScaled.current = true;
      suppressTapEvents.current = true;

      clearInterval(_longpressTimer.current);
      _longpressTimer.current = 0;

      clearTimeout(_pressEventTimer.current);
      _pressEventTimer.current = 0;
      return;
    }
    if(PINCH_ZOOM_FEATURE_ENABLED && e.touches.length > 1) {
      e.preventDefault();

      //
      // Need to update our typescript dependency in order to avoid `as any`, the zoom property was
      // not standardized until late April 2024 and did not exist on types in any release prior to
      // May 2024.
      //
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (document.body.style as any)['zoom'] = '0.99999';
    }

    //
    // If the user was just performing a browser natice pinch-zoom gesture, we don't want to handle
    // any touch gestures until they return to the default zoom level of 1.
    //
    if(viewportWasScaled.current) {
      return;
    }

    // const preventHandlingTouchEvent = shouldIgnoreTouchTarget?.call(touchTarget, e);
    // if(preventHandlingTouchEvent) {
    //   // We should not handle this touch event because our `touchFilter` callback has classified
    //   // this touch as being intended for some other touch target like a `button`, `a`, or UI
    //   // outsite of `.prompterContent`.
    //   _lastTimeStamp.current = 0;
    //   return;
    // }

    //
    // Given a set of touches on the screen and the initial set of touches from the start of the
    // gesture start, figure out what gesture is in progress and return information about it.
    //
    const currentGesture = calculateGesture(e.touches, initialTouches.current);
    if(currentGesture) {
      const {
        gesture,
        distanceMoved,
        scale,
        initialAngle,
      } = currentGesture;

      if(gesture === 'pinch') {
        // We may have a pinch-zoom gesture happening
        pinchZoom?.call(touchTarget, {
          scale,
          angle: initialAngle || 0,
        });

        // Suppress the press events below
        suppressTapEvents.current = true;
      }

      if(gesture === 'touch') {
        // This is a regular, 1 finger touch on the screen
        suppressTapEvents.current = distanceMoved > 1;
      }
    }

    //
    // If the user has moved their finger more than a couple pixels, we will suppress the press
    // events as this is likely the user scrolling or swiping.
    //
    if(suppressTapEvents.current) {
      clearInterval(_longpressTimer.current);
      _longpressTimer.current = 0;

      // cancel press timer if we move the touch:
      clearTimeout(_pressEventTimer.current);
      _pressEventTimer.current = 0;
    }

    // Fire `touchStart` callback, if configured for this hook
    touchMove?.call(touchTarget, e);
  }, [_longpressTimer, _pressEventTimer, viewportWasScaled, shouldIgnoreTouchTarget, touchMove, touchTarget]);

  const handleTouchEnd = useCallback((e: TouchEvent) => {
    const elapsed = Date.now() - _lastTimeStamp.current;
    // console.log(`touch end (was touched for ${elapsed}ms)`);

    //
    // Clear the interval and counter for any longpress repeats. If we were long pressing, we are
    // certainly done now after 'touchend'.
    //
    _repeatCount.current = 0;
    if (_longpressTimer.current) {
      clearInterval(_longpressTimer.current);
      _longpressTimer.current = 0;
    }

    //
    // If we filtered out handling for touch in `touchstart` handled using
    // `shouldIgnoreTouchTarget()` based on the DOM element being touched, then we set
    // lastTimeStamp to 0.
    //
    // This means we touched something outside of the prompter content and don't want to
    // interfere with regular menus, modal dialogs, etc.
    //
    if(_lastTimeStamp.current === 0) {
      // We filtered out (prevented handling of) the `touchstart` before this `touchend`
      return;
    }

    //
    // If we didn't return above, then we ARE handling touch events right now and might be handling
    // a double-tap. We need to update our lastTimestamp value in order to detect double-taps.
    //
    _lastTimeStamp.current = Date.now();

    //
    // If the visualViewport.scale is > 1 it means the user has managed to perform browser natice
    // pinch-zoom gesture despite our best efforts to prevent it. In order to make sure they don't
    // get stuck zoomed in, we won't process any touch events until they return to standard zoom
    // scale of 1.
    //
    if(getViewportScale() > 1) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (document.body.style as any)['zoom'] = '1';
      return;
    }

    //
    // If the user was just performing a browser natice pinch-zoom gesture, we don't want to handle
    // any touch gestures until they return to the default zoom level of 1.
    //
    if(viewportWasScaled.current) {
      viewportWasScaled.current = false;
      return;
    }

    // Fire `touchEnd` callback, if configured for this hook
    touchEnd?.call(touchTarget, e);

    // If pressed time was < long press interval, then
    // queue pressed event for just long than the double
    // click interval
    if(!suppressTapEvents.current) {
      if (elapsed < CONF_LONG_PRESS_MS && !_multipressInProgress.current) {
        // We pressed the button for less time than a long a press and not a double press.
        _pressEventTimer.current = window.setTimeout(() => {
          // Press occured (touchstart -> touchend, without moving in between and without
          // initiating double-tap)
          //
          // Fire `touchedCallback` callback, if configured for this hook
          touchedCallback?.call(touchTarget);
        }, 200);
      } else if (_multipressInProgress.current) {
        // We are double-tapping (or triple-tapping, or more)
        _pressEventTimer.current = window.setTimeout(() => {
          // Double-press occurred (touchstart -> touchend, < CONF_DOUBLE_CLICK_MS elapsed,
          // touchstart -> touchend)
          //
          // Fire `doubleTouchedCallback` callback, if configured for this hook
          doubleTouchedCallback?.call(touchTarget);
        }, 200);
      }
    }

    // Clear our touch event suppression. If this touchend is the end of a multi-touch gesture like
    // pinch-zoom or the end of touch based scrolling where the user moved their finger, we will
    // have suppressed any potential tap events for this touchend.
    if(!e.touches.length) {
      suppressTapEvents.current = false;
    }

    // Clear state of any detected double-press
    _multipressInProgress.current = false;


  }, [viewportWasScaled, touchEnd, touchedCallback, doubleTouchedCallback, _lastTimeStamp, _repeatCount, _longpressTimer, _multipressInProgress, _pressEventTimer]);

  useEffect(() => {
    const activeOptions: AddEventListenerOptions = { passive: false };
    const passiveOptions: AddEventListenerOptions = { passive: true };

    // Subscribe to touch events
    touchTarget.addEventListener('touchstart', handleTouchStart, passiveOptions);
    touchTarget.addEventListener('touchmove', handleTouchMove, activeOptions);
    touchTarget.addEventListener('touchend', handleTouchEnd, passiveOptions);

    // Clean-up
    return () => {
      // Unsubscribe to touch events
      touchTarget.removeEventListener('touchstart', handleTouchStart, passiveOptions);
      touchTarget.removeEventListener('touchmove', handleTouchMove, activeOptions);
      touchTarget.removeEventListener('touchend', handleTouchEnd, passiveOptions);
    };
  }, [touchTarget, handleTouchStart, handleTouchMove, handleTouchEnd]);

}

export default useTouchInput;