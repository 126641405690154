//
// EditorOperationsMessage.ts
//
// Slate Types:
// operations: BaseOperation[]
// type Operation = NodeOperation | BaseSetSelectionOperation | TextOperation
//
// type: "set_selection" | "insert_node" | "merge_node" | "move_node" | "remove_node" | "set_node" | "split_node" | "insert_text" | "remove_text"
//
import { BaseControlMessage } from './BaseControlMessage';
export const EDITOPERATIONMESSAGE_KEY = 'editor.operations';
/**
 * Represents the EditorOperationsMessage type.
 */
export class EditorOperationsMessage extends BaseControlMessage {
    constructor(operations, selection) {
        super();
        this.type = EDITOPERATIONMESSAGE_KEY;
        this.operations = operations;
        this.selection = selection;
    }
    static fromJson(data) {
        if (data.type !== EDITOPERATIONMESSAGE_KEY) {
            throw new SyntaxError('Invalid JSON for EditorOperationsMessage.');
        }
        const input = data;
        const instance = new EditorOperationsMessage(input.operations, input.selection);
        instance.deserializeSenderInfo(data.sender);
        return instance;
    }
    toJSON() {
        return {
            type: this.type,
            sender: this.sender?.toJSON(),
            operations: this.operations,
            selection: this.selection,
        };
    }
}
EditorOperationsMessage.MESSAGE_NAME = EDITOPERATIONMESSAGE_KEY;
