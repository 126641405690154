import React, { useCallback, useEffect } from 'react';

import TourContainer from '../components/AppTour/TourContainer';
import PrompterDrawer from '../components/PrompterDrawer';
import PrompterViewport from '../components/PrompterViewport';
import PrompterViewportUIContainer from '../components/PrompterViewportUIContainer';

import { EndpointRole } from '@fluidprompter/core';

import ShotLogReviewDialog from '../components/ShotLogReviewDialog';
import SelectDeviceDialog from '../components/SelectDeviceDialog';
import PeerConnectionAccountRequired from '../components/PeerConnectionAccountRequired';

import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { useAppController } from '../controllers/AppController';
import usePrompterSession from '../state/PrompterSessionState';
import { shallow } from 'zustand/shallow';

import { logDecorator } from '../utils/Logger';

function TeleprompterPage() {

  const prompterSession = usePrompterSession(state => ({
    prompterId: state.prompterId,
    setPrompterId: state.setPrompterId,
    hasUnsavedChanges: state.hasUnsavedChanges,
  }), shallow);

  const [searchParams, setSearchParams] = useSearchParams();
  const prompterId = searchParams.get('prompterId')?.toUpperCase();

  //
  // An event handler for the browser's 'beforeunload' event that will cause the browser to confirm
  // with the user before navigating away from FluidPrompter. This helps prevent accidental
  // navigation when the user presses shortcut keys like Cmd+Left, Cmd+Right on macos.
  //
  const confirmPageUnload = useCallback((e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = '';

    // Prompt the user to save changes?
  }, []);
  React.useEffect(() => {
    // We only want to tie into beforeunload if we have unsaved changes.
    if(!prompterSession.hasUnsavedChanges) {
      return;
    }

    window.addEventListener('beforeunload', confirmPageUnload);
    // window.addEventListener('unload', doSomethingOnUnload)
    return () => {
      window.removeEventListener('beforeunload', confirmPageUnload);
      // window.removeEventListener('unload', doSomethingOnUnload)
      // doSomethingOnUnload()
    };
  }, [prompterSession.hasUnsavedChanges, confirmPageUnload]);

  React.useEffect(() => {
    if(!prompterId) {
      // console.log('Generating new prompterId!');
      const newPrompterId = uuidv4().split('-')[0].toUpperCase();
      const searchParams2 = { ...searchParams, prompterId: newPrompterId };
      setSearchParams(searchParams2, { replace: true });
    }

    if(prompterId && prompterId !== prompterSession.prompterId) {
      prompterSession.setPrompterId(prompterId);
    }

    // Whenever our prompterId changes, update our log decorator.
    logDecorator.set('prompterId', prompterId);

    // Return a clean-up function that is used if the component is re-rendered.
    // return () => cancelAnimationFrame(requestRef.current);
  }, [searchParams, setSearchParams, prompterId, prompterSession]);

  const appController = useAppController();
  useEffect(() => {
    //
    // Set the browser window/tab title.
    //
    document.title = 'FluidPrompter App';

    //
    // Let our AppController instance know we are currently operating with the prompter role.
    //
    usePrompterSession.getState().setEndpointRole(EndpointRole.Prompter);
  }, [appController]);

  return (
    <TourContainer>
      {/* This is our main Prompter content that will be scrolled. */}
      <PrompterViewport />
      <PrompterViewportUIContainer />

      {/* PrompterOverlay should contain the masks <PrompterOverlay /> */}
      {/* MaskTop */}
      {/* MaskTop */}
      {/* MaskBottom */}

      {/* PrompterDrawer will render in portal, position fixed, zIndex 1200 */}
      <PrompterDrawer endpointRole={EndpointRole.Prompter} />

      <ShotLogReviewDialog />
      <SelectDeviceDialog />
      <PeerConnectionAccountRequired />
    </TourContainer>
  );
}

export default TeleprompterPage;