import React, { useCallback, useEffect, useRef } from 'react';
import { EditMessage, EndpointRole, NavigateMessage, PauseMessage, PlayMessage, SetScrollSpeedMessage, SyncMessage } from '@fluidprompter/core';

import { IViewportRefs } from './usePrompterViewportRefs';
import PositionLedgerEntry from './PositionLedgerEntry';

import { useAppController, useMessageHandler } from '../../controllers/AppController';
import useConfigurationStore from '../../state/ConfigurationStore';
import usePrompterSession from '../../state/PrompterSessionState';
import { shallow } from 'zustand/shallow';
import useTouchInput from '../../hooks/useTouchInput';
import useStatsCollector from '../../hooks/useStatsCollector';
import throttle from 'lodash/throttle';

const CURSOR_HIDE_TIMEOUT = 2000;
const MOUSE_TRAVEL_REQUIRED_TO_SHOW_CURSOR = 400;
const MOUSE_TRAVEL_REQUIRED_TO_PREVENT_HIDE_CURSOR = 50;
const SUSPEND_SCROLLING_DURATION_AFTER_USER_INTERACTION = 500;
const SUSPEND_SCROLLING_DURATION_WHILE_TOUCH_ACTIVE = 5000;
const CHECK_SCROLLING_CONTINUED_TIMEOUT = 250;

/**
 * Scale a number value value by a given ratio, adjusting the affect of the scale ratio with a
 * sensitity number (scaling the effect of scaling).
 * @param value The original value you want to scale
 * @param scale The scaling ratio express as a positive number between 0 and infinity, where 1 is
 * neutral
 * @param sensitivity The sensitivity value will increase or decrease the effect of the scaling
 * ratio. For example, 0.5 will reduce the effect of scaling in 1/2.
 * @returns The scaled value
 */
const scaleValueWithSensitivity = (value: number, scale: number, sensitivity: number) => {
  const scaledValue = value * scale;

  // The change in value produced by the scaling factor alone, we will further scale the change
  // using our sensitivity factor.
  const deltaValue = scaledValue - value;

  return value + (deltaValue * sensitivity);
};

function usePrompterCursorManagement(viewportRefs: IViewportRefs) {
  const endpointRole = usePrompterSession(state => state.endpointRole);

  const prompter = usePrompterSession(state => ({
    isPlaying: state.isPlaying,
    isPaused: state.isPaused,
    isEditing: state.isEditing,
  }), shallow);

  const cursor = usePrompterSession(state => ({
    cursorHidden: state.cursorHidden,
    setCursorHidden: state.setCursorHidden,
  }), shallow);

  const collectMouseMove = useStatsCollector();

  const appController = useAppController();

  // Reference to hold accumulated scroll amount between animation frames.
  const amountRef = useRef<number>(0);

  // Reference to hold setTimeout handle for hide cursor timer.
  const timeoutRef = useRef<number>(0);

  const dispatchUpdatesThrottled = useCallback(throttle(() => {
    const scrollAmount = amountRef.current;

    if(usePrompterSession.getState().isPlaying) {
      const scrollSpeed = useConfigurationStore.getState().scrollSpeed;
      appController.dispatchMessage(new SetScrollSpeedMessage(scrollSpeed + scrollAmount));
    } else {
      appController.dispatchMessage('prompter.scrollby', {
        deltaY: scrollAmount
      });
    }

    amountRef.current = 0;
  }, 1000/60), []);

  /**
   * Function that will set our cursor hidden if it is not already hidden and the prompter is not
   * in edit mode (cursor hiding is disabled in edit mode)
   */
  const hideCursor = useCallback(() => {
    const currentState = usePrompterSession.getState();
    if(!currentState.cursorHidden && !currentState.isEditing) {
      currentState.setCursorHidden(true);

      if(document.activeElement) {
        // Make sure no user inputs are currently focused, stealing keypresses.
        const activeElement = document.activeElement as HTMLElement;
        activeElement.blur();  // THIS WAS CAUSING SLATE EDITOR TO LOSE FOCUS
      }
    }
  }, []);

  /**
   * Function that will clear any current hide cursor timeout and request we hide the cursor
   * immediately.
   */
  const expireHideCursorTimer = useCallback(() => {
    if(timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Immediately hide the cursor.
    hideCursor();
  }, [timeoutRef, hideCursor]);
  useMessageHandler('prompter.local.HideCursorTimer.Expire', expireHideCursorTimer);

  /**
   * Function used to request we show the cursor. This function is throttled as it may be called
   * many times within a mouse move event.
   */
  const showCursorThrottled = useCallback(throttle(() => {
    if(cursor.cursorHidden) { cursor.setCursorHidden(false); }
    clearTimeout(timeoutRef.current);
    timeoutRef.current = window.setTimeout(hideCursor, CURSOR_HIDE_TIMEOUT);
  }, 1000), [cursor, timeoutRef, hideCursor]);
  useMessageHandler('prompter.local.HideCursorTimer.Restart', showCursorThrottled);

  // After initial rendering of the MouseInputMask, start our 3 second timer
  // to hide the mouse cursor.
  useEffect(() => {
    if(prompter.isEditing) {
      // If we are transition from play/pause -> editing, we want to show the cursor.
      showCursorThrottled();
      return;
    }

    //
    // If we get here we are transition away from editing -> play/pause and want to hide the cursor
    // in the configured hide timeout interval.
    //
    timeoutRef.current = window.setTimeout(hideCursor, CURSOR_HIDE_TIMEOUT);

    return () => { clearTimeout(timeoutRef.current); };
  }, [prompter.isEditing, hideCursor]);

  useEffect(() => {
    //
    // Reset the wheel accumulator when we toggle play/pause.
    //
    amountRef.current = 0;

    //
    // Is we are transitioning from editing/paused to playing, expire the hide cursor timer.
    //
    if(prompter.isPlaying) {
      expireHideCursorTimer();
    }
  }, [prompter.isPlaying, expireHideCursorTimer]);

  const handleMouseMove = useCallback((e: MouseEvent) => {
    const { cursorHidden, isEditing } = usePrompterSession.getState();
    if(!cursorHidden || isEditing) {
      // We only want to intercept the mouse move event when the prompter is not in edit mode.
      return;
    }

    // Calculate the line of sight distance the cursor moved.
    // https://stackoverflow.com/a/20916980
    const distanceMoved = Math.hypot(Math.abs(e.movementX), Math.abs(e.movementY));

    // Use our stats collector hook to calculate mouse movement stats over
    // the last second.
    const distanceOverLastSecond = collectMouseMove(distanceMoved);

    // If the user is moving the mouse around enough we think its intentional,
    // then show the mouse cursor and hidden UI elements again.
    // If the cursor is already visible, the threshhold of movement required
    // to keep it visible is lower than the threshhold required to initial
    // show the cursor.
    if(distanceOverLastSecond.sum > (usePrompterSession.getState().cursorHidden ? MOUSE_TRAVEL_REQUIRED_TO_SHOW_CURSOR : MOUSE_TRAVEL_REQUIRED_TO_PREVENT_HIDE_CURSOR)) {
      showCursorThrottled();
    }
  }, [collectMouseMove, showCursorThrottled]);

  const handleMouseWheel = useCallback((e: WheelEvent) => {
    if(!e.target) {
      return;
    }
    const targetHTMLElement = e.target as HTMLElement;
    const closestPrompterContent = targetHTMLElement.closest('.PrompterContainer');
    if(closestPrompterContent === null) {
      // We scrolled over an element outside of .PrompterContent - perhaps a menu or modal
      // dialog. We do not want to suppress the default ability to scroll a menu or modal dialog
      // via the mouse wheel.
      return;
    }

    //
    // If prompter mouse controls are enabled, we want to intercept the mousewheel event,
    // preventDefault, and instead control the scrolling speed using the mouse wheel.
    //
    const { mouseControlsEnabled } = useConfigurationStore.getState();
    if(mouseControlsEnabled) {
      e.preventDefault();
      e.stopPropagation();

      amountRef.current += Math.round(e.deltaY / 2);
      dispatchUpdatesThrottled();
      return;
    }

    //
    // If prompter mouse controls are not enabled, we will just be scrolling the script. Let's set
    // the flag indicating it was user-initiated scrolling, suspend any automated scrolling, and
    // make sure we are the leader.
    //
    const { isEditing } = usePrompterSession.getState();
    if(!isEditing) {
      //
      // When we receive user input based scroll events, we want to suspend our automated
      // scrolling animation. By setting this `userScrollingInProgress` flag we will interpret
      // viewport scroll events as user generated scroll events (vs automated prompter scrolling).
      //
      // Also user initiated scrolling should cause this endpoint to become the leader.
      //
      if(!userScrollingInProgress.current) {
        appController.deviceHost.setLeaderIsSelf();

        userScrollingInProgress.current = true;
        // console.log('userScrollingInProgress = true (mouse wheel)');
      }
      return;
    }
  }, [amountRef, endpointRole]);

  //
  // "PointerEvent" will fire for any type of pointing device whether a tradition mouse or a touch
  // screen. PointerEvent will allow us to differentiate between mouse and touch and allow us to
  // prevent the traditional 'click' event if desired.
  //
  const handlePointerEvent = useCallback((e: PointerEvent) => {
    const { isEditing, isPlaying } = prompter;

    //
    // We only want to intercept the mouse click event when the prompter is not in edit mode.
    //
    if(isEditing) {
      return;
    }

    // If this "Mouse click" is a result of a touch screen, ignore it. We want to have touch
    // specific behavior defined elsewhere.
    if(e.pointerType === 'touch') { // 'mouse' | 'touch' | 'pen'
      return;
    }

    //
    // We aren't interested in mouse clicks that are not on the prompter content (ie: clicks on
    // a menu or modal dialog should be not be intercepted). We also don't want to intercept clicks
    // on a button or anchor element such as Word Limit Notice CTAs or End Element buttons to
    // restart script.
    //
    if(e.button === 0) {
      //
      // We should never have a null event target for a real (user generated) click event.
      // Synthetic click events could have no event target but won't be considered for prompter
      // control.
      //
      if(!e.target) {
        return;
      }
      const targetHTMLElement = e.target as HTMLElement;

      //
      // Clicks inside of a button or anchor element should not be intercepted.
      // Any buttons or anchors that should not be clicked while prompting should have a css
      // `pointer-events: none` rule added to them while the prompter is not in edit mode.
      //
      const closestButton = targetHTMLElement.closest('button,a,.allow-click-while-prompting');
      if (closestButton !== null) {
        // We clicked a button or anchor element... don't intercept this event.
        return;
      }

      //
      // Mouse clicks outside of the PrompterContent don't need to be intercepted.
      //
      const closestPrompterContent = targetHTMLElement.closest('.PrompterContainer');
      if (
        closestPrompterContent === null
      ) {
        // We clicked outside of .PrompterContent - perhaps a menu or modal dialog.
        // Do not intercept this event.
        return;
      }
    }

    //
    // If we get here, we want to intercept this click event and will interpret it as potential
    // prompter control command if mouse controls are enabled.
    //
    e.preventDefault();
    e.stopPropagation();

    const { mouseControlsEnabled } = useConfigurationStore.getState();
    if(!mouseControlsEnabled) {
      // Mouse based prompter controls are not currently enabled.
      return;
    }

    // For left click on MacBook
    // button: 0
    // buttons: 0
    // which: 1

    // For right click on MacBook
    // button: 2
    // buttons: 2
    // which: 3
    switch(e.button) {
      case 0: { // Left click
        appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.PageUp));
        break;
      }
      case 1: { // Middle click
        appController.dispatchMessage(isPlaying ? new PauseMessage() : new PlayMessage());
        break;
      }
      case 2: { // Right click
        appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.PageDown));
        break;
      }
      default:
        break;
    }
  }, [appController, endpointRole, prompter]);


  /**
   * Returns true if we should ignore the incoming touch event for the purpose of touch based
   * prompter control. We don't want to interfere with touch use of certain UI elements such as
   * toolbars, modal dialogs or menus.
   */
  const shouldIgnoreTouchTarget = useCallback((e: TouchEvent): boolean => {
    //
    // Don't handle touch events while prompter is in edit mode
    //
    const { isEditing, isPlaying } = usePrompterSession.getState();
    if(isEditing) {
      // Prevent handling of this touch event
      return true;
    }

    //
    // We should never have a null event target for a real (user generated) click event.
    // Synthetic click events could have no event target but won't be considered for prompter
    // control.
    //
    if(!e.target) {
      return false; // Allow this touch event to be handled
    }
    const targetHTMLElement = e.target as HTMLElement;

    //
    // Touches inside of a button or anchor element should not be intercepted.
    // Any buttons or anchors that should not be clicked while prompting should have a css
    // `pointer-events: none` rule added to them while the prompter is not in edit mode.
    //
    const closestButton = targetHTMLElement.closest('button,a');
    if (closestButton !== null) {
      // We touched a button or anchor element... don't intercept this event.
      return true;
    }

    //
    // Touches outside of the PrompterContent don't need to be intercepted.
    //
    const closestPrompterContent = targetHTMLElement.closest('.PrompterContainer');
    if (
      closestPrompterContent === null
    ) {
      // We touched outside of .PrompterContent - perhaps a menu or modal dialog.
      // Do not intercept this event.
      return true;
    }

    // Do not prevent handling of this touch event
    // Allow handling of the touch event
    return false;
  }, []);

  //
  // If true, we will evaluate `touchmove` events for potential scroll animation suspension.
  // We will only allow this if `touchstart` was on the prompter content and not a button, link,
  // toolbar or menu outside the promtper content.
  //
  const activeTouchesCount = useRef(0);
  const touchScrollingEligible = useRef(false);
  const handleTouchStart = useCallback((e: TouchEvent) => {
    activeTouchesCount.current = e.touches.length;

    //
    // If we receive touchmove events, we are ok to evaluate them for user scrolling.
    //
    touchScrollingEligible.current = true;

    //
    // Save our initial text size and content width when we begin a pinch-zoom gesture
    //
    const {
      textSize,
      contentWidth,
    } = useConfigurationStore.getState();
    initialAppearanceState.current = {
      textSize,
      contentWidth,
    };

    //
    // When we are in play mode (not paused or editing) we want to prevent touch based scrolling
    // or accidental UI interaction.
    //
    // DEPRECATED? After touching new touch based scroll suspending the automated scrolling
    // algorithm, then we can delete these lines of code.
    //
    // if(isPlaying) {
    // e.preventDefault();
    // e.stopPropagation();
    // }
  }, [activeTouchesCount, touchScrollingEligible]);

  //
  // To detect the end of user scrolling we will use a timeout mechanism. A timeout mechanism is
  // required to detect the end of momentum based scrolling which may continue to scroll after the
  // user has released their finger (after `touchend`).
  //
  const userScrollingTimeout = useRef(0);

  //
  // If we throttle scroll event we need both leading and trailing options. Leading ensures we do
  // not add latency to the stream of scrolling events. Trailing ensures that the last event in the
  // stream is always transmitted which is important as the last event will adjust the
  // `suspendScrollingForMs` after touch based scrolling ends with `touchend`.
  //
  const syncScrollToPeers = (ledger: PositionLedgerEntry, throttled?: boolean) => {
    // console.log(`syncScrollToPeers(throttled=${throttled === true})`);
    appController.dispatchMessage(new SyncMessage(true));
  };
  const syncScrollToPeersThrottled = throttle(syncScrollToPeers, 1000/30);  // , { leading: true, trailing: true }

  const userDidScroll = useCallback((suspendTime: number, throttled?: boolean) => {
    // console.log(`userDidScroll(suspendTime=${suspendTime}ms, throttled=${throttled === true})`);

    //
    // This will be included in SenderInfo in any messages sent to connected peers.
    appController.scrollSuspendedMs = suspendTime;

    //
    // First, apply the suspend time to our local prompter.
    const ledger = viewportRefs.previousLedgerRef.current;
    if(!ledger) {
      return;
    }
    ledger.suspendScrollingForMs(suspendTime);

    //
    // And now, transmit the sync information to any connected peers.
    throttled === true
      ? syncScrollToPeersThrottled(ledger, throttled)
      : syncScrollToPeers(ledger, throttled);
  }, [viewportRefs.previousLedgerRef, appController, syncScrollToPeers]);

  const syncWhenUserScrollingStops = useCallback((suspendScrollingForMs: number) => {
    //
    // We want to detect if momentum based scrolling has stopped using a timeout mechansim. The
    // timeout is cleared + reset during any scroll event so that the timerout callback never fires
    // until scrolling has stopped for the requested duration.
    //
    clearTimeout(userScrollingTimeout.current);
    userScrollingTimeout.current = window.setTimeout(() => {
      userScrollingInProgress.current = false;
      // console.log('userScrollingInProgress = false');

      // Perform one last sync! The suspend time is shortened by the length of this timeout.
      userDidScroll(suspendScrollingForMs - CHECK_SCROLLING_CONTINUED_TIMEOUT);
    }, CHECK_SCROLLING_CONTINUED_TIMEOUT);
  }, [userScrollingTimeout, userDidScroll]);

  const handleTouchEnd = useCallback((e: TouchEvent) => {
    activeTouchesCount.current = e.touches.length;
    touchScrollingEligible.current = false;

    //
    // User has released their finger(s) from the touch screen. We want to detect if scrolling has
    // stopped (momentum based scrolling may continue after the user released their finger(s))
    //
    if(userScrollingOccuredDuringTouch.current) {
      userScrollingOccuredDuringTouch.current = false;

      syncWhenUserScrollingStops(SUSPEND_SCROLLING_DURATION_AFTER_USER_INTERACTION);
    }

    //
    // Clear pinch-zoom state
    //
    if(initialAppearanceState.current && e.touches.length < 2) {
      initialAppearanceState.current = {
        textSize: 0,
        contentWidth: 0,
      };
    }
  }, [activeTouchesCount, syncWhenUserScrollingStops]);

  //
  // If `touchstart` was on prompter content (touchScrollingEligible === true), then we will
  // consider `touchmove` an indication that we might be scrolling the content because of user
  // input.
  //
  const userScrollingInProgress = useRef(false);

  //
  // If `touchmove` fired between `touchstart` and `touchend`, and the viewport was scrolled, then
  // user scrolling did occur. If user scrolling did occur, we want to know in the `touchend` event
  // handler.
  //
  const userScrollingOccuredDuringTouch = useRef(false);

  const handleTouchMove = useCallback((e: TouchEvent) => {
    //
    // When we receive user input based scroll events, we want to suspend our automated
    // scrolling animation. By setting this `userScrollingInProgress` flag we will interpret
    // viewport scroll events as user generated scroll events (vs automated prompter scrolling).
    //
    // Also user initiated scrolling should cause this endpoint to become the leader.
    //
    if(touchScrollingEligible.current && !userScrollingInProgress.current) {
      appController.deviceHost.setLeaderIsSelf();

      userScrollingInProgress.current = true;
      // console.log('userScrollingInProgress = true (touch)');
    }

    if(
      initialAppearanceState.current.textSize <= 0
        || initialAppearanceState.current.contentWidth <= 0
    ) {
      const {
        textSize,
        contentWidth,
      } = useConfigurationStore.getState();
      initialAppearanceState.current = {
        textSize,
        contentWidth,
      };
    }
  }, [viewportRefs]);

  const onViewportScrolled = useCallback((e: Event /*e: React.UIEvent<HTMLDivElement, UIEvent>*/) => {
    const ledger = viewportRefs.previousLedgerRef.current;
    if(!ledger) {
      return;
    }

    const scrollEl = document.scrollingElement;
    if(!scrollEl) {
      return;
    }

    const scrollRange = Math.max(0, scrollEl.scrollHeight - scrollEl.clientHeight);
    const scrollPosition = useConfigurationStore.getState().flipVertical
      ? scrollRange - scrollEl.scrollTop
      : scrollEl.scrollTop;

    //
    // Calculate how many pixels we have scrolled the Y axis.
    //
    // By having some threshold for detecting the end of user initiated momentum scrolling we can
    // avoid an excessive trailing time where it decelerates too slowly at the end. We will require
    // some minimum number of pixels of scroll moment to have occur during an event in order to
    // renew our scroll suspension timeout.
    //
    const scrollYDelta = Math.abs(scrollPosition - ledger.scrollPosition);

    //
    // If user scrolling is in progress, and the scroll position changed enough, renew our scroll
    // suspension.
    //
    if(
      (prompter.isPaused
        || userScrollingInProgress.current)
        && scrollYDelta >= 1
    ) {
      userScrollingOccuredDuringTouch.current = true;

      //
      // If the user is currently touching the screen, we will use a longer scrolling suspend
      // duration while the user precisely positions the script at the cue.
      //
      // When we receive 'touchend' event we will re-evaluate the suspend scrolling duration.
      //
      const touchActive = activeTouchesCount.current > 0;
      const suspendScrollingForMs = touchActive
        ? SUSPEND_SCROLLING_DURATION_WHILE_TOUCH_ACTIVE
        : SUSPEND_SCROLLING_DURATION_AFTER_USER_INTERACTION;

      //
      // If user scrolling is in progress now, we want to detect when momentum based scrolling is
      // finished by checking if the scroll position changed after some time from now.
      //
      syncWhenUserScrollingStops(suspendScrollingForMs);

      // Reset our scrolling suspend duration now and send a sync message to peers if the scroll
      // position changed by at least 1 pixel (note: the scroll event can fire with a 0 pixel
      // change either because it was a fraction of a pixel change or a 0 change during decaying
      // momentum based scrolling).
      userDidScroll(suspendScrollingForMs, true);
    }

    //
    // Update our position ledger with the new user scroll position.
    //
    if(
      prompter.isPaused
        || userScrollingInProgress.current
    ) {
      ledger.scrollPosition = scrollPosition;
    }
  }, [viewportRefs, prompter.isPaused]);

  const initialAppearanceState = useRef({
    textSize: 0,
    contentWidth: 0,
  });

  //
  // This hook will attach event listeners on the specified `touchTarget` for touchstart,
  // touchmove, touchend in order to detect the following touch gestures: tapped,
  // double-tapped, long-tapped (with repeat), double-long-tapped (with repeat).
  //
  useTouchInput({
    name: 'usePrompterCursorManagement',
    shouldIgnoreTouchTarget,
    touchStart: handleTouchStart,
    touchMove: handleTouchMove,
    touchEnd: handleTouchEnd,
    touchTarget: document,
    touchedCallback: () => {
      //
      // A single tap should show the hidden UI, if currently hidden.
      //
      // This callback will not fire if the user moved their finger significantly while touched
      // (ie: scrolling) or if the user presented multiple touches (2+ fingers).
      //
      // Additionally our `shouldIgnoreTouchStart` filter function above ensures we are not
      // handling touch events while in edit mode or when the touch event target is a clickable
      // button, link or UI outside of PromtperContainer (a toolbar, modal dialog or menu).
      //
      showCursorThrottled();
    },
    doubleTouchedCallback: () => {
      //
      // A double tap should toggle play/pause state.
      //
      // This callback will not fire if the user moved their finger significantly while touched
      // (ie: scrolling) or if the user presented multiple touches (2+ fingers).
      //
      // Additionally our `shouldIgnoreTouchStart` filter function above ensures we are not
      // handling touch events while in edit mode or when the touch event target is a clickable
      // button, link or UI outside of PromtperContainer (a toolbar, modal dialog or menu).
      //
      appController.dispatchMessage('prompter.state.toggleplay');
    },
    longTouchedCallback: () => {
      //
      // A long touch should set prompter mode to edit.
      //
      // This callback will not fire if the user moved their finger significantly while touched
      // (ie: scrolling) or if the user presented multiple touches (2+ fingers).
      //
      // Additionally our `shouldIgnoreTouchStart` filter function above ensures we are not
      // handling touch events while in edit mode or when the touch event target is a clickable
      // button, link or UI outside of PromtperContainer (a toolbar, modal dialog or menu).
      //
      appController.dispatchMessage(new EditMessage());
    },
    pinchZoom(e) {
      const { scale } = e;

      //
      // Let's reduce the sensitivity of pinch-zoom text size adjustments.
      //
      const proposedTextSize = scaleValueWithSensitivity(
        initialAppearanceState.current.textSize,
        scale,
        0.5
      );

      // The `setTextSize()` method alread validates the proposed value and bounds it to
      // 10 - 100 as a safety catch.
      useConfigurationStore.getState().setTextSize(proposedTextSize);
    },
  });

  useEffect(() => {
    const captureOptions: AddEventListenerOptions = { capture: true, passive: false };

    // Subscribe to mouse events at the page level
    document.addEventListener('mousemove', handleMouseMove, captureOptions);
    document.addEventListener('wheel', handleMouseWheel, captureOptions);

    document.addEventListener('pointerup', handlePointerEvent);

    window.addEventListener('scroll', onViewportScrolled);

    // Clean-up
    return () => {
      // Unsubscribe to mouse events.
      document.removeEventListener('mousemove', handleMouseMove, captureOptions);
      document.removeEventListener('wheel', handleMouseWheel, captureOptions);

      document.addEventListener('pointerup', handlePointerEvent);

      window.removeEventListener('scroll', onViewportScrolled);
    };
  }, [handleMouseMove, handleMouseWheel, handlePointerEvent, handleTouchMove, onViewportScrolled]);
}

export default usePrompterCursorManagement;