import React, { useCallback, useEffect } from 'react';

import { Editor, Descendant, Range, BaseSelection, NodeEntry, Node, Path, Text } from 'slate';
import { Slate, Editable, ReactEditor } from 'slate-react';
import { PrompterMode } from '@fluidprompter/core';

import { PrompterViewportProps } from '..';
import { PrompterDecoration, PrompterEditor } from '../../../models/EditorTypes';
import SlateFocusMonitor from '../SlateFocusMonitor';
import { FormatCommands } from '../useEditorFormatCommandHandlers';
import { VoiceTypingCommands } from '../useVoiceTypingCommandHandlers';

import usePrompterSession from '../../../state/PrompterSessionState';
import { shallow } from 'zustand/shallow';

import HoveringToolbar from '../HoveringToolbar';
import StartElementSpacer from '../StartElementSpacer';
import SegmentSubscribe from '../SegmentSubscribe';
import HorizontalLine from '../HorizontalLine';

import renderElement from './renderElement';
import { renderLeaf } from './renderLeaf';
import classNames from 'classnames';

export interface PrompterEditorRendererProps
  extends PrompterViewportProps
{
  prompterContentRef: React.MutableRefObject<HTMLDivElement | null>;
  onPrompterContentRendered: () => void;

  lineHeight: number;
  readOnly: boolean;

  editor: PrompterEditor;
  editableKey: number;
  formatCommands: FormatCommands;
  voiceTypingCommands: VoiceTypingCommands;
  // document: Descendant[];

  onChange: (doc: Descendant[]) => void;
  onKeyDown: React.KeyboardEventHandler<HTMLDivElement>;
  onPaste: React.ClipboardEventHandler<HTMLDivElement>;
}

const PrompterEditorRenderer = React.memo(function PrompterEditorRenderer(props: PrompterEditorRendererProps) {

  const {
    prompterContentRef,
    onPrompterContentRendered,

    lineHeight,
    readOnly,

    textSize,
    textColor,
    contentWidth,
    leftGutter,
    rightGutter,
    flipHorizontal,
    flipVertical,

    editableKey,
    editor,
    onChange,
    onKeyDown: onKeyDownHandler,
  } = props;

  // console.log('PrompterContentRender() method');
  const scriptNodes = usePrompterSession(state => state.scriptNodes);

  const prompterSession = usePrompterSession(state => ({
    shotlogEntries: state.shotlogEntries,
    scriptNodesMeta: state.scriptNodesMeta,
    // scriptNodesState: state.scriptNodesState,
    getScrollPositionMax: state.getScrollPositionMax,
  }), shallow);
  const { shotlogEntries, getScrollPositionMax } = prompterSession;

  // const currentScrollPosition = scriptNodesState?.currentScrollPosition || 0;
  const scrollPositionMax = getScrollPositionMax();
  const lastShotlogEntry = shotlogEntries.length ? shotlogEntries[shotlogEntries.length - 1] : undefined;
  // console.log(`scrollPositionMax=${scrollPositionMax}`, lastShotlogEntry);

  //
  // Figure out our list of CSS Transforms that apply any configured flipping
  // horizontally or vertically as well as scroll position.
  //
  const transformDirectives: string[] = [];
  /* transformDirectives.push(`translateY(-${scrollPosition}px)`); */
  if(flipHorizontal) { transformDirectives.push('rotateY(180deg)'); }
  if(flipVertical) { transformDirectives.push('rotateX(180deg)'); }
  /*
   * This causes the component to not re-render when disabling both flipHorizontal and flipVertical.
   * The only purpose of this was to try and force GPU compositing, there is no functional purpose.
   * Maybe in future we can consolidate the rotateX() rotateY() and transale3d() into one transform?
   *
  if(!flipHorizontal && !flipVertical) {
    //
    // If we have no desire to flip the prompter content in either direction, we still want a CSS
    // transform in order to hint to the browser that we want GPU acceleration used on this element
    // for animation.
    //
    transformDirectives.push('transale3d(0,0,0)');
  }
  */
  const contentTransform = transformDirectives.join(' ');

  const decoratePrompterState = usePrompterSession(state => ({
    prompterMode: state.prompterMode,
    editorSelection: state.editorSelection,
    remoteEditorFocused: state.remoteEditorFocused,
  }), shallow);
  const decorateHandler = useCallback((entry: NodeEntry<Node>): PrompterDecoration[] => {
    const decorations: PrompterDecoration[] = [];
    const [node, path] = entry;

    const {
      prompterMode,
      editorSelection,
      remoteEditorFocused,
    } = decoratePrompterState;

    //
    // If the current select is collapsed and contained in the current node and the current node
    // is an empty text leaf node, then show our editor placeholder.
    //
    if (
      prompterMode !== PrompterMode.Playing &&
      editor.selection !== null &&
      !Editor.isEditor(node) &&
      Editor.string(editor, path) === '' &&
      Range.includes(editor.selection, path) &&
      Range.isCollapsed(editor.selection)
    ) {
      decorations.push({
        ...editor.selection,
        placeholder: true,
      });
    }

    //
    // If we have a remote editor prompter peer who is actively editing the script, then let's show
    // the remote editors cursor and selection.
    //
    if(
      prompterMode !== PrompterMode.Playing
        && editorSelection
        && remoteEditorFocused
        && Range.isExpanded(editorSelection)
        && !Editor.isEditor(node)
        && Range.includes(editorSelection, path)
    ) {
      decorations.push({
        anchor: editorSelection.anchor,
        focus: editorSelection.focus,
        isSelection: true,
      });
    }
    if(
      prompterMode !== PrompterMode.Playing
        && editorSelection
        && remoteEditorFocused
        && Range.isCollapsed(editorSelection)
        && !Editor.isEditor(node)
        && Path.equals(path, editorSelection.focus.path)
    ) {
      decorations.push({
        anchor: editorSelection.anchor,
        focus: editorSelection.focus,
        isCursor: true,
      });
    }

    return decorations;
  }, [editor, readOnly, decoratePrompterState]);

  //
  // If we transition to readonly, make sure the editor is not focused
  //
  useEffect(() => {
    if(readOnly) {
      ReactEditor.blur(editor);
    }
  }, [readOnly]);

  //
  // Use a callback ref to intercept ref assignment so that we know when PrompterContent is
  // re-rendered in the DOM.
  //
  // If we just adjusted prompter content appearance (content width, text size, left gutter, line
  // height) then we may want to restore the script position that was cached before making the
  // configuration change.
  //
  const prompterContentCallbackRef: React.RefCallback<HTMLDivElement> = (newRef: HTMLDivElement) => {
    prompterContentRef.current = newRef;

    onPrompterContentRendered();
  };

  return (
    <>
      <Slate editor={editor} value={scriptNodes} onChange={onChange}>
        <div
          className={classNames('PrompterContent', {
            ReadOnly: readOnly,
            FlipHorizontal: flipHorizontal,
            FlipVertical: flipVertical,
          })}
          ref={prompterContentCallbackRef}
          style={{
            color: textColor,
            // transform: contentTransform,
            width: `${contentWidth}%`,
            marginLeft: `${leftGutter}%`,
            fontSize: `${textSize}pt`,
            lineHeight: `${lineHeight}`,
          }}
          //onContextMenu={handleContextMenu}
        >
          <StartElementSpacer />
          <Editable
            key={editableKey}   // change the key to restart editor w/ new editorValue
            className='ContentEditor'
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            decorate={decorateHandler}
            // onKeyDown={onKeyDown}
            onKeyDownCapture={onKeyDownHandler}
            onPaste={props.onPaste}
            readOnly={readOnly}
            spellCheck={false}
          />
          {lastShotlogEntry && <HorizontalLine caption="Last ShotLog Entry" position={Math.floor((lastShotlogEntry.endScriptPosition || lastShotlogEntry.startScriptPosition) * scrollPositionMax)} />}
          <SegmentSubscribe />
        </div>
        <SlateFocusMonitor />
      </Slate>
      <HoveringToolbar editor={editor} editorRect={prompterContentRef.current?.getBoundingClientRect()} formatCommands={props.formatCommands} voiceTypingCommands={props.voiceTypingCommands} />
    </>
  );
});

export default PrompterEditorRenderer;