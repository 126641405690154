import { SenderInfo } from '@fluidprompter/core';

import useConfigurationStore from '../state/ConfigurationStore';
import usePrompterSession from '../state/PrompterSessionState';

import { ElementTypes, PrompterElement } from '../models/EditorTypes';

function calculateScrollSpeed(leaderInfo: SenderInfo) {
  if(!leaderInfo) {
    return;
  }

  const { mode: leaderMode, scrollSpeed, scrollReversed, scriptPosition } = leaderInfo;
  if(scrollSpeed === undefined) {
    console.log('calculateScrollSpeed() leaderInfo.scrollSpeed is undefined, returning.');
    return;
  }

  const configState = useConfigurationStore.getState();
  const prompterSession = usePrompterSession.getState();

  //
  // By default, we will scale the scrolling speed based on the overall prompter content
  // height. This will be slightly inaccurate as each individual node may or may not scale
  // linearly.
  //
  // Note: A cloud remote will have `contentHeight = undefined` as it has no rendered script.
  // - If the sender is a cloud remote, senderScalingHeight will end up 0;
  // - If this local endpoint is a cloud remote, localScalingHeight will end up 0;
  // In either case, we won't scale the scrollSpeed if either end is a cloud remote.
  //
  let senderScalingHeight = leaderInfo.contentHeight || 0;
  let localScalingHeight = prompterSession.scriptNodesMeta?.contentHeight || 0;
  const proposedUserScrollSpeed = (localScalingHeight === 0 || senderScalingHeight === 0)
    ? scrollSpeed
    : Math.floor(scrollSpeed * localScalingHeight / senderScalingHeight);

  //
  // If we have a more accurate script position (after first render) then we can scale the
  // scrolling speed based on the height of the current node.
  //
  if(scriptPosition) {
    const senderNodePath = scriptPosition.nodePath;
    const localNode = prompterSession.getScriptNode(senderNodePath);
    const localNodeMeta = prompterSession.getNodeMetaByPath(senderNodePath);

    if(localNodeMeta) {
      const elementType = (localNode as PrompterElement)?.type;
      switch(elementType) {
        case ElementTypes.STARTSCRIPT:
          senderScalingHeight = scriptPosition.nodeHeight;
          localScalingHeight = localNodeMeta.height;
          break;
        case ElementTypes.PARAGRAPH:
          senderScalingHeight = scriptPosition.nodeHeight;
          localScalingHeight = localNodeMeta.height;
          // console.log(`We are in a paragraph ${localScalingHeight}/${senderScalingHeight}`);
          break;
        default:
          if(scriptPosition.position > (scriptPosition.nodeChildrenTop + scriptPosition.nodeChildrenHeight)) {
            // We are in the footer of the current node.
            senderScalingHeight = scriptPosition.nodeHeight - scriptPosition.nodeChildrenHeight - scriptPosition.nodeChildrenTop;
            localScalingHeight = localNodeMeta.height - localNodeMeta.childrenBottom + localNodeMeta.top;
            // console.log(`We are in the footer ${localScalingHeight}/${senderScalingHeight}`);
          } else if(scriptPosition.position >= scriptPosition.nodeChildrenTop) {
            // We are in the body of the current node.
            senderScalingHeight = scriptPosition.nodeChildrenHeight;
            localScalingHeight = localNodeMeta.childrenHeight;
            // console.log(`We are in the body ${localScalingHeight}/${senderScalingHeight}`);
          } else {
            // We are in the header of the current node.
            senderScalingHeight = scriptPosition.nodeChildrenTop;
            localScalingHeight = localNodeMeta.childrenTop - localNodeMeta.top;
            // console.log(`We are in the header ${localScalingHeight}/${senderScalingHeight}`);
          }
          break;
      }
    }
  }

  //
  // I would rather follower prompters be slightly slower than the leader (Math.floor) than
  // slightly faster as we never want our sync position to move backwards. I'd rather sync
  // position slightly boosts the scroll speed to catch up vs momentarily pausing or reversing
  // to slow down. TLDR: Math.floor() is best.
  //
  // If localScalingHeight === 0 this is a Remote instance with no contentHeight as there is
  // no content in the viewport to measure the height of.
  // If this is a remote, we just want to set the scrollSpeed as is, as sent from the remote
  // peer, unscaled.
  //
  const proposedScrollSpeed = (localScalingHeight === 0 || senderScalingHeight === 0)
    ? scrollSpeed
    : Math.floor(scrollSpeed * localScalingHeight / senderScalingHeight);

  // if(localScalingHeight) {
  //   console.log(`Scaled scroll speed ${proposedScrollSpeed} = ${scrollSpeed} * ${localScalingHeight} / ${senderScalingHeight}`);
  // }

  if(configState.userScrollSpeed !== proposedUserScrollSpeed
    || configState.scrollSpeed !== proposedScrollSpeed
    || configState.scrollReversed !== scrollReversed
  ) {
    configState.setScrollSpeed(proposedScrollSpeed, proposedUserScrollSpeed, scrollReversed);
  }
  //
  // I am evaluating if in future we can consolidate synchronization logic into less code paths.
  // In future `syncScrollSpeed()` and `syncScrollPosition()` and changes in play/pause/edit state
  // could perhaps be consolidated into one common code path that does not change for different
  // types of app messages. In this case even handling message for PlayMessage, PauseMessage, etc
  // will only use the common state synchronization code path and no unique handling.
  //
  // if(prompterSession.prompterMode !== leaderMode) {
  //   // The current leader is in a different mode (play/pause/edit) than this prompter.
  //   prompterSession.setPrompterMode(leaderMode);
  // }
}

export default calculateScrollSpeed;