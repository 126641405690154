import React, { useRef } from 'react';
import {
  Range,
  BaseSelection,
} from 'slate';
import { PrompterEditor } from '../../../models/EditorTypes';

import usePrompterSession from '../../../state/PrompterSessionState';
import useVisualViewport from '../../../hooks/useVisualViewport';
import { FormatCommands } from '../useEditorFormatCommandHandlers';
import { VoiceTypingCommands } from '../useVoiceTypingCommandHandlers';

import Box from '@mui/material/Box';

import HoveringToolbarComponent from './HoveringToolbarComponent';
import SelectionCollapsedTools from './SelectionCollapsedTools';
import SelectionExpandedTools from './SelectionExpandedTools';
import AlwaysAvailableTools from './AlwaysAvailableTools';

import { isIOS } from 'react-device-detect';
import './HoveringToolbar.scss';

interface HoveringToolbarProps {
  editor: PrompterEditor;
  editorRect?: DOMRect;
  formatCommands: FormatCommands;
  voiceTypingCommands: VoiceTypingCommands;
}

const HoveringToolbar = React.memo(function HoveringToolbar(props: HoveringToolbarProps) {
  const { editor, formatCommands, voiceTypingCommands } = props;

  const editorFocused = usePrompterSession(state => state.editorFocused);

  const { visualViewport } = useVisualViewport();

  const ref = useRef<HTMLDivElement | null>();

  const { selectionCollapsed, selectionExpanded } = usePrompterSession(state => state.editorSelectionState);

  //
  // Determine whether we should show the hovering toolbar and where.
  //
  /*
  useEffect(() => {
    const el = ref.current
    const { selection } = editor

    if (!el || !props.editorRect) {
      return
    }

    if (
      !selection ||
      !inFocus ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ''
    ) {
      // el.removeAttribute('style')
      el.style.opacity = '0';
      return
    }

    const domSelection = window.getSelection()
    if(!domSelection) {
      // We have no dom selection. Don't show the hovering toolbar.
      return;
    }

    // Get container bouning rect
    // PrompterContent
    const containerEl = el.closest('.PrompterContent');
    if(!containerEl) {
      return;
    }

    const domRange = domSelection.getRangeAt(0)
    const selectionRect = domRange.getBoundingClientRect();
    el.style.opacity = '1';
    el.style.top = `${selectionRect.top - el.offsetHeight - props.editorRect.top}px`

    // console.log(`selection Left ${selectionRect.left}, containerLeft: ${props.editorRect.left}, selection Width: ${selectionRect.width}, toolbar Width: ${el.offsetWidth}`);

    //
    // The toolbar should be centered over the selection, except it also should go beyond the left
    // and right edges of the editor (which happens when the toolbar is wider than the selection
    // width).
    //
    const maxLeft = props.editorRect.width - el.offsetWidth;
    let proposedLeft = selectionRect.left - props.editorRect.left + ((selectionRect.width - el.offsetWidth) / 2);
    if(proposedLeft < 0) { proposedLeft = 0; }
    if(proposedLeft > maxLeft) { proposedLeft = maxLeft; }
    el.style.left = `${proposedLeft}px`
  });
  */

  //
  // On Ben's iPad Pro PWA, the window.innerHeight is reduced by the amount of the
  // visualViewport.offsetTop when the soft keyboard is opened.
  //
  // Also on Ben's iPad when the visual viewport is smaller than the layout viewport,
  // an additional 24 px is taken up by the iOS status bar at the top with battery level,
  // clock, etc.
  //
  const controlBarHeight = isIOS ? 76 : 63; // iPad needs an extra 13 pixels padding below the ControlBar for the iOS swipe up bar thing.
  const toolbarBottom = ((window.innerHeight - visualViewport.height) < 1)
    ? controlBarHeight
    : (window.innerHeight - visualViewport.height - visualViewport.offsetTop + (isIOS ? 24 : 0));

  //
  // Rendering HoveringToolbar
  //
  return (
    <Box
      className='HoveringToolbar'
      ref={ref}
      sx={{
        bottom: toolbarBottom,
        opacity: (editorFocused && editor.selection) ? 1 : 0,
        pointerEvents: (editorFocused && editor.selection) ? 'all' : 'none',
      }}
    >
      <HoveringToolbarComponent disableGutters={true} variant='dense' onMouseDown={(e) => {
        e.preventDefault();
      }}>
        {selectionCollapsed && <SelectionCollapsedTools editor={editor} />}
        {selectionExpanded && <SelectionExpandedTools editor={editor} commands={formatCommands} />}
        <AlwaysAvailableTools editor={editor} voiceTypingCommands={voiceTypingCommands} />
      </HoveringToolbarComponent>
    </Box>
  );
});

export default HoveringToolbar;