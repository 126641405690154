import { useCallback } from 'react';
import { IViewportRefs } from './usePrompterViewportRefs';
import usePrompterSession from '../../state/PrompterSessionState';

export interface ScrollToArgs {
  scrollPosition: number;
  scrollBehavior?: ScrollBehavior;
  scrollTime?: number;
  suspendPlayingForMs?: number;
  abortController?: AbortController;
}

interface GetPrompterPositionConfigStoreSlice {
  flipVertical: boolean,
}

const usePrompterScrollToPositionFunction = function(viewportRefs: IViewportRefs, configStore: GetPrompterPositionConfigStoreSlice) {

  const { flipVertical } = configStore;

  /**
   * Promise version of scrollToPromise. Returns a promise that will resolve when we reach the
   * target position or reject if we never reach the target.
   */
  const scrollToPositionPromise = useCallback(function (args?: ScrollToArgs): Promise<void> {
    // console.log(`event prompter.handleScrollTo ${targetPosition} (current ${currentLedger.scrollPosition}) ${prompterSession.isPlaying}`)
    const promiseInstance = new Promise<void>((resolve, reject) => {
      const currentLedger = viewportRefs.previousLedgerRef.current;
      if(!args || !currentLedger || args.scrollPosition === undefined) {
        reject(new Error('Invalid args or no previousLedger.'));
        return;
      }

      const scrollEl = document.scrollingElement;
      const physicalScrollPosition = scrollEl?.scrollTop || 0;
      const maxPosition = scrollEl ? (scrollEl.scrollHeight - scrollEl.clientHeight) : 0;
      const logicalScrollPosition = flipVertical ? maxPosition - physicalScrollPosition : physicalScrollPosition;
      const ac = args.abortController;

      //
      // We will apply the proposed `suspendPlayingForMs` regardless of whether we return early
      // because we are already at the target position or need to animate scrolling to the target
      // position.
      //
      if(args.suspendPlayingForMs) {
        currentLedger.suspendScrollingForMs(args.suspendPlayingForMs);
      }

      //
      // Enforce the requested scroll position is within the accepted range of 0 to maxPosition.
      //
      let targetPosition = args.scrollPosition;
      if(targetPosition < 0) { targetPosition = 0; }
      if(targetPosition > maxPosition) { targetPosition = maxPosition; }

      if(ac && ac.signal.aborted) {
        // We were aborted before we even started!
        reject(new Error(`ScrollToPosition() Abort (targetPosition: ${targetPosition}, currentPosition: ${physicalScrollPosition})`));
      }

      //
      // Should we check if we are already at the target position?
      // If so, we really don't need to do anything!
      //
      if(Math.round(logicalScrollPosition) === Math.round(targetPosition)) {
        // console.log('ScrollToPosition() already complete');
        currentLedger.scrollPosition = targetPosition;
        resolve();
        return;
      }

      //
      // Attach a listener to the scroll event.
      // We will resolve the promise when we have scrolled to the target position.
      //
      // Allow for some variance from the exact target scroll position by using a min and max range.
      // We will allow for the scrolling algorithm to overshoot the target in the direction of scroll.
      //
      const isScrollingDown = targetPosition > logicalScrollPosition;
      const targetMin = Math.floor(targetPosition) - (isScrollingDown ? 1 : 10);
      const targetMax = Math.ceil(targetPosition) + (isScrollingDown ? 10 : 1);
      const scrollHandler = () => {
        const physicalScrollHandlerPosition = document.scrollingElement?.scrollTop || 0;
        const logicalScrollHandlerPosition = flipVertical
          ? maxPosition - physicalScrollHandlerPosition
          : physicalScrollHandlerPosition;

        // We can also detect the direction of scroll and allow for a great range in the direction of the scroll to allow for "overshooting".
        // if(el.scrollTop > targetMin
        //   && el.scrollTop < targetMax) {
        if((isScrollingDown && logicalScrollHandlerPosition >= targetMin)
          || (!isScrollingDown && logicalScrollHandlerPosition <= targetMax)
        ) {
          // We have arrived at our target scroll position!
          clearTimeout(scrollTimeout);
          window.removeEventListener('scroll', scrollHandler);
          // console.log('scrollToPosition complete');

          // TODO: This next line may be buggy in the event that this promise is already rejected. We should remove the onScroll event listener on reject as well as resolve.
          // currentLedger.scrollPosition = targetPosition;  // This is update in our onScroll event handler, but that's throttled, so let's set it now.
          resolve();
        }
      };
      window.addEventListener('scroll', scrollHandler, { passive: true });

      //
      // Safety, just in case...
      //
      const scrollTimeout = setTimeout(() => {
        window.removeEventListener('scroll', scrollHandler);
        reject(new Error(`ScrollToPosition() Timeout (targetPosition: ${targetPosition}, currentPosition: ${document.scrollingElement?.scrollTop})`));
      }, 3000);

      //
      // Abort controller allows external callers to abort our promise to scroll
      // to a particular position (perhaps because another request came in to scroll
      // to some other position).
      //
      if(ac) {
        ac.signal.addEventListener('abort', () => {
          window.removeEventListener('scroll', scrollHandler);
          reject(new Error(`ScrollToPosition() Abort (targetPosition: ${targetPosition}, currentPosition: ${document.scrollingElement?.scrollTop})`));
        });
      }

      // Calculate the offset between the current scroll position and the target scroll position.
      const skipOffset = targetPosition - currentLedger.scrollPosition;

      // The caller can optionally provide a custom skip animation length
      let skipTimeTotal = args.scrollTime || 350;
      if(args.scrollBehavior === 'auto') {
        // No animation - instant scroll position adjustment please.
        skipTimeTotal = 0;
      }

      // console.log(`prompter.scrollto offset:${skipOffset}px = targetPosition:${targetPosition}px - currentPosition:${currentLedger.scrollPosition}px`);
      currentLedger.setCurrentSkip(skipOffset, skipTimeTotal);

      // Request animation of the above skip offset - if we are already playing (and therefor
      // animating), this will do nothing.
      usePrompterSession.getState().ensureAnimating();
    });

    return promiseInstance;
  }, [viewportRefs, flipVertical]);

  /**
   * Fire and forget method for scrollToPosition. Returns void, doesn't care if we actually get to
   * the target position or not.
   * @param args
   */
  const scrollToPosition = useCallback((args?: ScrollToArgs) => {
    scrollToPositionPromise(args).catch((e) => {
      // We don't care if the `scrollToPosition()` promise gets rejected because another
      // scroll request comes in. If we do care, we will call `scrollToPositionPromise` directly.
    });
  }, [scrollToPositionPromise]);

  return {
    scrollToPositionPromise,
    scrollToPosition,
  };
};

export default usePrompterScrollToPositionFunction;