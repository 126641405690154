import { useRef, useCallback } from 'react';
import { Descendant } from 'slate';

import usePrompterSession from '../../state/PrompterSessionState';

export interface PushLastScrollTargetArgs {
  scrollPosition: number,
  segment?: Descendant | number
}

export interface ScrollPositionQueueEntry {
  scrollPosition: number,
  segmentIndex?: number,
  abortController: AbortController,
}

export interface ScrollPositionWithAbortController {
  scrollPosition: number,
  abortController: AbortController,
}
// type ScrollPositionQueueEntry = SegmentIndexWithAbortController | ScrollPositionWithAbortController;

const useSegmentNavigationFunctions = function() {

  const lastTargetSegmentNumber = useRef<ScrollPositionQueueEntry[]>([]);

  const getSegmentIndex = useCallback(function(targetSegment: Descendant) {
    const { scriptNodes } = usePrompterSession.getState();
    return scriptNodes.findIndex((segment) => segment === targetSegment);
  }, []);

  const getSegmentIndexRelativeToActive = useCallback(function (offsetFromCurrent: number) {
    const {
      scriptNodes,
      currentScriptNode,
      currentScriptNodeIndex,
    } = usePrompterSession.getState();

    let targetSegmentIndex = currentScriptNodeIndex;

    const targetSegmentQueue = lastTargetSegmentNumber.current;
    if(targetSegmentQueue.length > 0) {
      // We have a targetSegmentNumber, so calculate relative to this.
      const { segmentIndex } = targetSegmentQueue[targetSegmentQueue.length - 1];
      // console.log(`getSegmentIndexRelativeToActive() = ${segmentIndex}`)

      if(segmentIndex !== undefined) {  // 0 is a valid index. Explicitly check for undefined.
        targetSegmentIndex = segmentIndex;
      }
    }

    // Else if we have no lastTargetSegmentNumber, then check the state for currently active segment.
    /*
      * TODO: Refactor after moving to Slate!!
      */
    if(targetSegmentIndex < 0) {
      targetSegmentIndex = scriptNodes.findIndex((segment) => segment === currentScriptNode);
    }

    targetSegmentIndex = targetSegmentIndex + offsetFromCurrent;

    const maxIndex = scriptNodes.length - 1;
    if(targetSegmentIndex < 0) { targetSegmentIndex = 0; }
    if(targetSegmentIndex >= maxIndex) { targetSegmentIndex = maxIndex; }

    return targetSegmentIndex;
  }, []);

  const pushLastScrollTarget = useCallback(function (args: PushLastScrollTargetArgs): ScrollPositionQueueEntry {

    const targetSegmentQueue = lastTargetSegmentNumber.current;

    //
    // Grab our previous queue entry if there is one. We are going to abort it.
    //
    let previousQueueEntry: ScrollPositionQueueEntry | undefined;
    if(targetSegmentQueue.length > 0) {
      previousQueueEntry = targetSegmentQueue[targetSegmentQueue.length - 1];
    }

    const queueEntry: ScrollPositionQueueEntry = { scrollPosition: args.scrollPosition, abortController: new AbortController() };

    if(args.segment !== undefined) {
      const segment = args.segment;

      //
      // Keep track of the last segment we tried to navigate too.
      // In the case of a quick succession of commands to go forward, back, to segment #, etc
      // the commands may come in quicker than the length of the animation to scroll to that
      // position.
      //
      let targetSegmentIndex = typeof segment === 'object' ? getSegmentIndex(segment as Descendant) : segment;

      const maxIndex = usePrompterSession.getState().scriptNodes.length - 1;
      if(targetSegmentIndex < 0) { targetSegmentIndex = 0; }
      if(targetSegmentIndex >= maxIndex) { targetSegmentIndex = maxIndex; }

      //
      // Let's push our new queue entry before we abort any outstanding entries.
      //
      queueEntry.segmentIndex = targetSegmentIndex;
    }

    targetSegmentQueue.push(queueEntry);
    // console.log(`pushLastScrollTarget()`, JSON.stringify(queueEntry), JSON.stringify(targetSegmentQueue))

    //
    // Abort the previous queue entry.
    //
    if(previousQueueEntry) {
      previousQueueEntry.abortController.abort();
    }

    return queueEntry;
  }, [getSegmentIndex, lastTargetSegmentNumber]);

  const shiftLastScrollTarget = useCallback(function (): ScrollPositionQueueEntry | undefined {
    const targetSegmentQueue = lastTargetSegmentNumber.current;
    const result = targetSegmentQueue.shift();
    // console.log(`shiftLastScrollTarget() = ${targetSegmentQueue}`)
    return result;
  }, [lastTargetSegmentNumber]);

  const clearLastTargetSegmentIndex = useCallback(function () {
    lastTargetSegmentNumber.current = [];
  }, [lastTargetSegmentNumber]);

  return {
    getSegmentIndex,
    getSegmentIndexRelativeToActive,
    pushLastScrollTarget,
    shiftLastScrollTarget,
    clearLastTargetSegmentIndex
  };
};

export default useSegmentNavigationFunctions;