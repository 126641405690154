import React, { Suspense } from 'react';
import { useCallback } from 'react';
import { MessageHandlerEvent, useMessageHandler } from '../../controllers/AppController';
import { GenericMessage } from '@fluidprompter/core';

import useApplicationState from '../../state/ApplicationState';
import { shallow } from 'zustand/shallow';
import { zaraz } from 'zaraz-ts';

const VerifyEmailDialogRenderer = React.lazy(() => import('./VerifyEmailDialogRenderer'));

function VerifyEmailDialog() {

  const appState = useApplicationState(state => ({
    isVerifyEmailOpen: state.isVerifyEmailOpen,
    setVerifyEmailOpen: state.setVerifyEmailOpen,
  }), shallow);

  const handleClose = () => {
    appState.setVerifyEmailOpen(false);
  };

  const showHelp = useCallback(async function (e: MessageHandlerEvent<GenericMessage>) {
    e.sendToPeers = false;

    // Fire analytics event for help.
    // zaraz.track('app_event', {
    //   event_name: 'app_showhelp'
    // });

    useApplicationState.getState().setVerifyEmailOpen(true);
  }, []);
  useMessageHandler('prompter.local.showhelp', showHelp);

  return (
    <Suspense>
      {appState.isVerifyEmailOpen && <VerifyEmailDialogRenderer handleClose={handleClose} />}
    </Suspense>
  );
}

export default VerifyEmailDialog;