import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import WindowMode from '../models/WindowMode';

interface ApplicationState {
  menuOpen: boolean;
  setMenuOpen: (open: boolean) => void;
  toggleMenuOpen: () => void;

  isShotLogOpen: boolean;
  setShotLogOpen: (open: boolean) => void;

  isAccountSettingsOpen: boolean;
  setAccountSettingsOpen: (open: boolean) => void;
  isComparePlansOpen: boolean;
  setComparePlansOpen: (open: boolean) => void;

  isVerifyEmailOpen: boolean;
  setVerifyEmailOpen: (open: boolean) => void;

  isAccountDeleteOpen: boolean;
  setAccountDeleteOpen: (open: boolean) => void;

  isHelpOpen: boolean;
  setHelpOpen: (open: boolean) => void;

  remoteDialogOpen: boolean;
  setRemoteDialogOpen: (open: boolean) => void;

  bluetoothDialogOpen: boolean;
  setBluetoothDialogOpen: (open: boolean) => void;

  connectDeviceDialogOpen: boolean;
  setConnectDeviceDialogOpen: (open: boolean) => void;

  windowMode: WindowMode;
  setWindowMode: (position: WindowMode) => void;
}

// [DEPRECATED] `getStorage`, `serialize` and `deserialize` options are deprecated. Use `storage` option instead.
const useApplicationState = create<ApplicationState>()(persist<ApplicationState>(
  (set) => ({
    menuOpen: false,
    setMenuOpen: (open: boolean) => set(() => ({ menuOpen: open })),
    toggleMenuOpen: () => set((state) => ({ menuOpen: !state.menuOpen })),

    isShotLogOpen: false,
    setShotLogOpen: (open: boolean) => set(() => ({ isShotLogOpen: open })),

    isAccountSettingsOpen: false,
    setAccountSettingsOpen: (open: boolean) => set((state) => ({
      menuOpen: false,
      isAccountSettingsOpen: open,
      isAccountDeleteOpen: open ? state.isAccountDeleteOpen : false,
    })),
    isComparePlansOpen: false,
    setComparePlansOpen: (open: boolean) => set((state) => ({ menuOpen: false, isAccountSettingsOpen: (open ? true : state.isAccountSettingsOpen), isComparePlansOpen: open })),

    isVerifyEmailOpen: false,
    setVerifyEmailOpen: (isVerifyEmailOpen: boolean) => set((state) => ({ menuOpen: false, isVerifyEmailOpen })),

    isAccountDeleteOpen: false,
    setAccountDeleteOpen: (open: boolean) => set(() => ({ isAccountDeleteOpen: open })),

    isHelpOpen: false,
    setHelpOpen: (open: boolean) => set(() => ({ isHelpOpen: open })),

    remoteDialogOpen: false,
    setRemoteDialogOpen: (open: boolean) => set(() => ({ remoteDialogOpen: open })),

    bluetoothDialogOpen: false,
    setBluetoothDialogOpen: (open: boolean) => set(() => ({ remoteDialogOpen: open })),

    connectDeviceDialogOpen: false,
    setConnectDeviceDialogOpen: (open: boolean) => set(() => ({ connectDeviceDialogOpen: open })),

    windowMode: WindowMode.UNKNOWN,
    setWindowMode: (windowMode: WindowMode) => set(() => ({ windowMode })),
  }),
  {
    name: 'fp-app-state', // name of item in the storage (must be unique)
    storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
  }
));

export default useApplicationState;