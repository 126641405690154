import React, { useEffect } from 'react';
import { EndpointRole } from '@fluidprompter/core';

import PrompterDrawer from '../components/PrompterDrawer';
import PrompterViewport from '../components/PrompterViewport';
import PrompterViewportUIContainer from '../components/PrompterViewportUIContainer';

import ShotLogReviewDialog from '../components/ShotLogReviewDialog';

import { useParams } from 'react-router-dom';

import { useAppController } from '../controllers/AppController';
import usePrompterSession from '../state/PrompterSessionState';

function ViewerPage() {

  //
  // Grab the URL parameters from react-router-dom to get our prompter session id.
  //
  const { prompterId: prompterIdParam } = useParams();
  const prompterId = prompterIdParam as string;

  React.useEffect(() => {
    usePrompterSession.getState().setPrompterId(prompterId);
  }, [prompterId]);

  const appController = useAppController();
  useEffect(() => {
    //
    // Set the browser window/tab title.
    //
    document.title = 'FluidPrompter Viewer';

    //
    // Let our AppController instance know we are currently operating with the viewer role.
    //
    usePrompterSession.getState().setEndpointRole(EndpointRole.Viewer);
  }, [appController]);

  return (
    <>
      {/* This is our main Prompter content that will be scrolled. */}
      <PrompterViewport />
      <PrompterViewportUIContainer />

      {/* PrompterOverlay should contain the masks <PrompterOverlay /> */}
      {/* MaskTop */}
      {/* MaskTop */}
      {/* MaskBottom */}

      {/* PrompterDrawer will render in portal, position fixed, zIndex 1200 */}
      <PrompterDrawer endpointRole={EndpointRole.Viewer} />
    </>
  );
}

export default ViewerPage;