import { useMessageHandler } from '../../controllers/AppController';

import DeviceHost from '../DeviceHost';

import usePrompterSession from '../../state/PrompterSessionState';

import logger from '../../utils/Logger';
import { useTranslation } from 'react-i18next';

function useRTCNegotiation(deviceHost: DeviceHost) {

  const log = logger.child({
    childName: 'useRTCNegotiation'
  });
  log.trace('useRTCNegotiation() constructor');

  const { t } = useTranslation('devices');

  useMessageHandler('sdp', async (e) => {
    const { message } = e;
    const { endpoint, targetId } = message;

    if(!e.originatedRemotely) {
      e.sendToPeers = true;
      return;
    }

    const myId = usePrompterSession.getState().instanceId;
    if(targetId !== myId) {
      log.error(`Received an SdpMessage not intended for this endpoint. ${targetId}`);
      return;
    }

    const peerInstance = deviceHost.getOrCreatePeerInstance(endpoint, t);

    // If getOrCreatePeerInstance() returned a new peer instance, we need to make sure it is in
    // a connecting state before it can make use of an SdpMessage.
    //
    // Connect won't do anything if the peer is already connecting or connected.
    await peerInstance.connect();

    await peerInstance.handleSdpMessage(message);
  });

  useMessageHandler('icecandidate', async (e) => {
    const { message } = e;
    const { sender, targetId } = message;

    if(!e.originatedRemotely) {
      e.sendToPeers = true;
      return;
    }

    const myId = usePrompterSession.getState().instanceId;
    if(targetId !== myId) {
      log.error(`Received an IceCandidateMessage not intended for this endpoint. ${targetId}`);
      return;
    }

    // const targetDevice = deviceHost.allDevices<PrompterPeerInstance>(DeviceConnectionType.Network)
    //   .find((device) => device.endpointId === sender?.id);
    const targetDevice = await deviceHost.promisePeerInstance(sender?.id);
    if(!targetDevice) {
      log.error(`Received IceCandidate for unknown device: senderId = '${sender?.id}', targetId = '${targetId}', my id = ${myId}`);
      return;
    }

    targetDevice.handleIceCandidateMessage(message);
  });
}

export default useRTCNegotiation;