import React, { useEffect, useState } from 'react';
import Button, { buttonClasses } from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';

import CloseIcon from '@mui/icons-material/Close';

import { styled } from '@mui/material/styles';
import classNames from 'classnames';

const NoticeButton = styled(Button)({
  fontSize: 'unset', //0.8125rem
  textDecoration: 'underline',
  backgroundColor: '#171700',
  borderColor: '#b6b600',
  color: '#b6b600',
  marginLeft: 8,
});

type NoticeBarType = 'info' | 'warning' | 'error';
type NoticeBarState = 'Entering' | 'Visible' | 'Leaving' | 'Hidden';

interface NoticeBarProps {
  type: NoticeBarType;
  animationName?: string;
  onAnimationEnd?: (e: AnimationEvent) => void;
  startIcon?: React.ReactNode;
  message?: string;
  ctaShortCaption?: string;
  ctaCaption?: string;
  ctaAction?: () => void;
  requestClose?: () => void;
  children?: React.ReactNode;
}

function NoticeBar(props: NoticeBarProps) {
  const {
    type,
    animationName,
    onAnimationEnd,
    startIcon,
    message,
    children,
    ctaShortCaption,
    ctaCaption,
    ctaAction,
    requestClose,
  } = props;

  const noticeBarRef = React.createRef<HTMLDivElement>();
  useEffect(() => {
    const noticeBarEl = noticeBarRef.current;
    if(!noticeBarEl) {
      return;
    }

    const handleAnimationEnd = (e: AnimationEvent) => {
      if(onAnimationEnd) { onAnimationEnd(e); }
    };

    noticeBarEl.addEventListener('animationend', handleAnimationEnd);

    return () => {
      noticeBarEl.removeEventListener('animationend', handleAnimationEnd);
    };
  }, [noticeBarRef]);

  return (<div
    ref={noticeBarRef}
    className={classNames('NoticeBar', animationName)}
  >
    {startIcon}
    {children}
    <Link
      component="button"
      variant="body2"
      className='NoticeButton'
      color='#b6b600'
      fontSize={'1em'}
      onClick={ctaAction}
    >
      <span className='ShortCta'>{ctaShortCaption}</span>
      <span className='Cta'>{ctaCaption}</span>
    </Link>
    {(requestClose /*|| ctaButton*/) && <div
      style={{
        flex: 1,
      }}
    ></div>}
    {/* {ctaCaption && <NoticeButton
      variant="outlined"
      // variant="contained"
      size="small"
      color="warning"
      onClick={ctaAction}
    >
      <span className='ShortCta'>{ctaShortCaption}</span>
      <span className='Cta'>{ctaCaption}</span>
    </NoticeButton>} */}
    {requestClose && <IconButton
      aria-label="close"
      onClick={requestClose}
      sx={{
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <CloseIcon />
    </IconButton>}
  </div>);
}

export default NoticeBar;