import { StateCreator } from 'zustand';

import { IPrompterModeSlice } from './PrompterModeSlice';
import { IPrompterViewportSlice } from './PrompterViewportSlice';
import { IPrompterScriptSlice } from './PrompterScriptSlice';
import { EndpointRole, PrompterMode } from '@fluidprompter/core';

export interface IPrompterAttributesSlice {
  prompterId: string;
  setPrompterId: (prompterId: string) => void;

  instanceId?: string;
  setInstanceId: (prompterId: string) => void;

  endpointRole: EndpointRole;
  setEndpointRole: (endpointRole: EndpointRole) => void;

  wordLimit?: number;
  setWordLimit: (wordLimit?: number) => void;

  elapsedPlayTime: number;
  elapsedPlaySeconds: number;
  estimatedRemainingSeconds: number;
  setElapsedPlayTime: (elapsedPlayMs: number) => void;

  cursorHidden: boolean;
  setCursorHidden: (hidden: boolean) => void;

  framesPerSecond: number;
  setFramesPerSecond: (fps: number) => void;
}

interface CalculateEstimatedTimeRemainingResults {
  estimatedRemainingSeconds?: number,
}
const calculateEstimatedTimeRemaining = (state: IPrompterAttributesSlice & IPrompterViewportSlice & IPrompterScriptSlice, elapsedPlayMs: number): CalculateEstimatedTimeRemainingResults => {
  const result = {};

  // If we are just too early in the application life cycle we won't have the information we need to estimate remaining time.
  if(!state.scriptNodesMeta || !state.scriptNodesState) {
    return result;
  }

  const contentHeight = state.scriptNodesMeta?.contentHeight;
  const viewportHeight = state.viewportMeta.viewportHeight;
  const currentScrollPosition = state.scrollPosition;

  const totalScrollRange = contentHeight - viewportHeight;
  // const percentageComplete = currentScrollPosition / totalScrollRange;

  const estimatedTotalMs = totalScrollRange * elapsedPlayMs / currentScrollPosition;
  const estimatedRemainingMs = estimatedTotalMs - elapsedPlayMs;
  const estimatedRemainingSeconds = Math.floor(estimatedRemainingMs / 1000);

  //if((estimatedRemainingSeconds < state.estimatedRemainingSeconds) || (estimatedRemainingSeconds > state.estimatedRemainingSeconds + 2)) {
  if(estimatedRemainingSeconds !== state.estimatedRemainingSeconds) {
  // result['estimatedRemainingSeconds'] = estimatedRemainingSeconds;

    // The number of estimated remaining seconds has changed! We don't want to return new values more frequently than the value actually changes to avoid unnecessary UI updates.
    return {
      estimatedRemainingSeconds
    };
  }

  return {};
};

const createPrompterAttributesSlice: StateCreator<
  IPrompterAttributesSlice & IPrompterModeSlice & IPrompterViewportSlice & IPrompterScriptSlice,
  [],
  [],
  IPrompterAttributesSlice
> = (set, get) => ({
  prompterId: '',
  setPrompterId: (prompterId: string) => set(() => ({ prompterId })),

  instanceId: undefined,
  setInstanceId: (instanceId: string) => set(() => ({ instanceId })),

  endpointRole: EndpointRole.Unknown,
  setEndpointRole: (endpointRole: EndpointRole) => set((state) => {
    let prompterMode: PrompterMode = state.prompterMode;
    if(
      endpointRole === EndpointRole.Viewer
        && prompterMode === PrompterMode.Editing
    ) {
      prompterMode = PrompterMode.Paused;
    }
    return ({ endpointRole, prompterMode });
  }),

  wordLimit: 100,
  setWordLimit: (wordLimit?: number) => set(() => ({ wordLimit })),

  elapsedPlayTime: 0,
  elapsedPlaySeconds: 0,
  estimatedRemainingSeconds: 0,
  setElapsedPlayTime: (elapsedPlayMs: number) => set((state) => {

    const { estimatedRemainingSeconds } = calculateEstimatedTimeRemaining(state, elapsedPlayMs);

    let stateUpdates = {
      elapsedPlayTime: elapsedPlayMs,
    };
    const proposedElapsedPlaySeconds = Math.ceil(elapsedPlayMs / 1000);
    if(state.elapsedPlaySeconds !== proposedElapsedPlaySeconds) {
      stateUpdates = Object.assign(stateUpdates, {
        elapsedPlaySeconds: proposedElapsedPlaySeconds,
      });
    }
    if(estimatedRemainingSeconds && state.estimatedRemainingSeconds !== estimatedRemainingSeconds) {
      stateUpdates = Object.assign(stateUpdates, {
        estimatedRemainingSeconds,
      });
    }

    return stateUpdates;
  }),

  cursorHidden: false,
  setCursorHidden: (hidden: boolean) => set(() => ({ cursorHidden: hidden })),

  framesPerSecond: 0,
  setFramesPerSecond: (fps: number) => {
    const state = get();

    if(fps !== state.framesPerSecond) {
      set(() => ({ framesPerSecond: fps }));
    }
  },
});

export default createPrompterAttributesSlice;