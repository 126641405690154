/**
 * Represents one logic endpoint involved in a prompter session. This may be a prompter, a web
 * remote, or a companion module for control via streamdeck.
 *
 * We compress EndpointRole in order to shorten the length of serialized JSON (to ensure all
 * messages fit in 1 network packet, 1 MTU), but also as a bit of obfuscation to deter reverse
 * engineering.
 *
 * The string value of the enum is only used for serialization/deserialization. Any code reference
 * should be readable as it uses the enum key.
 */
export var EndpointRole;
(function (EndpointRole) {
    EndpointRole["Unknown"] = "u";
    EndpointRole["Prompter"] = "p";
    EndpointRole["Viewer"] = "v";
    EndpointRole["Remote"] = "r";
    // API = 'API',
})(EndpointRole || (EndpointRole = {}));
export default EndpointRole;
