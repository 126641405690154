import React, { useState, useEffect, useRef, useCallback } from 'react';
import VideoPanel from './VideoPanel';
import MediaPanelToolbar from './MediaPanelToolbar';
import MediaPanelContextMenu from './MediaPanelContextMenu';

import useContextMenuState from './useContextMenuState';

import { useAppController, useMessageHandler } from '../../controllers/AppController';
import useConfigurationStore, { MediaPanelPosition, MediaScalingMode } from '../../state/ConfigurationStore';
import useFeatureFlagsStore from '../../state/FeatureFlagsStore';
import usePrompterSession, { MediaSourceType } from '../../state/PrompterSessionState';
import { shallow } from 'zustand/shallow';

import useResizeObserver from '@react-hook/resize-observer';
import { Id, toast, ToastOptions, TypeOptions, UpdateOptions } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';

import classNames from 'classnames';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import './PrompterMediaPanel.scss';

/** [MDN Reference](https://developer.mozilla.org/docs/Web/API/UserActivation) */
interface UserActivation {
  /** [MDN Reference](https://developer.mozilla.org/docs/Web/API/UserActivation/hasBeenActive) */
  readonly hasBeenActive: boolean;
  /** [MDN Reference](https://developer.mozilla.org/docs/Web/API/UserActivation/hasBeenActive) */
  readonly isActive: boolean;
}

const toastIds: Record<string, Id> = {};
const createOrUpdateToast = (deviceId: string, content: string, options?: UpdateOptions | undefined) => {
  const toastId = toastIds[deviceId];
  if(toastId && toast.isActive(toastId)) {
    // Update
    if(content && options) {
      options['render'] = content;
    }
    toast.update(toastId, options);
    return;
  }

  const toastOptions = options as ToastOptions;
  toastIds[deviceId] = toast(content, toastOptions);
};

const PrompterMediaPanel = React.memo(function PrompterMediaPanel() {

  // We will hide the <MediaPanelToolbar /> component on Sm or Xs viewport widths (typically a
  // mobile device like a phone).
  const theme = useTheme();
  const viewportSmOrXs = useMediaQuery(theme.breakpoints.down('sm'));

  const videoRef = React.createRef<HTMLVideoElement>();
  const canvasRef = React.createRef<HTMLCanvasElement>();
  const [fitWidth, setFitWidth] = useState<boolean>(true);
  const [mediaStream, setMediaStream] = useState<MediaStream>();
  const [mediaTrackSettings, setMediaTrackSettings] = useState<MediaTrackSettings>();

  const configStore = useConfigurationStore(state => ({
    flipHorizontal: state.flipHorizontal,
    flipVertical: state.flipVertical,

    mediaPanelPosition: state.mediaPanelPosition,
    mediaPanelWidth: state.mediaPanelWidth,
    mediaPanelHeight: state.mediaPanelHeight,
    media1ScalingMode: state.media1ScalingMode,
  }), shallow);

  const appController = useAppController();
  const confirm = useConfirm();

  const prompterSession = usePrompterSession(state => ({
    isBlanking: state.isBlanking,
    media1SourceType: state.mediaSourceType,
    cursorHidden: state.cursorHidden,
  }), shallow);

  const mediaPanelRef = React.createRef<HTMLDivElement>();

  //
  // This hook will manage the context menu state and provide callback functions to trigger the
  // menu.
  //
  const {
    contextMenuProps,
    showContextMenuAtAnchor,
    closeContextMenu,
    cancelTouchGestures,
  } = useContextMenuState(mediaPanelRef);

  /*
  const handleMediaPanelResized = useCallback(async (e?: ResizeObserverEntry) => {
    // console.log('handleBackgroundResized', e);
    const mediaPanelEl = mediaPanelRef.current;
    if(!mediaPanelEl || !mediaDimensions) {
      // console.log('ERROR handleBackgroundResized bailed...', mediaTrackSettings);
      return;
    }

    // const backgroundWidth = e?.contentRect.width || backgroundEl.clientWidth;
    // const backgroundHeight = e?.contentRect.height || backgroundEl.clientHeight;
    // const contentSmallerThanContainer = mediaTrackSettings.width < backgroundWidth && mediaTrackSettings.height < backgroundHeight;

    //
    // Determine how to fit the video in our background.
    // We can letter box on top and bottom or left and right.
    //
    const mediaAspectRatio = (e !== undefined) ? e.contentRect.width / e.contentRect.height : mediaPanelEl.clientWidth / mediaPanelEl.clientHeight;
    const videoAspectRatio = mediaDimensions.width / mediaDimensions.height;
    // console.log(`handleBackgroundResized, backgroundAspectRatio=${backgroundAspectRatio}, videoAspectRatio=${videoAspectRatio}`);

    const localConfigState = useConfigurationStore.getState();
    switch(localConfigState.media1ScalingMode) {
      case MediaScalingMode.Contain:
        setFitWidth(videoAspectRatio >= mediaAspectRatio);
        break;
      case MediaScalingMode.Cover:
        setFitWidth(videoAspectRatio < mediaAspectRatio);
        break;
    }
  }, [mediaPanelRef, mediaDimensions]);
  useResizeObserver(mediaPanelRef, handleMediaPanelResized);
  */

  useMessageHandler('background', async (e) => {
    // Enable the hidden media feature if this prompter peer receives a media track from
    // another connected peer.
    useFeatureFlagsStore.getState().setMediaSharing(true);

    console.log(`PrompterMediaPanel.setMediaStream(active = ${e.message.mediaStream.active})`, e.message.mediaStream);
    // setVideoTrack(e.message.mediaStream);
    setMediaStream(e.message.mediaStream);
  });


  useMessageHandler('prompter.background.clear', (e) => {
    e.sendToPeers = true;

    //
    // Grab a reference to our HTML5 video element
    //
    const videoEl = videoRef.current;
    if(!videoEl) {
      return;
    }

    //
    // Clear the videoSrc attribute on the HTML5 video element, if there was any set.
    //
    const videoSrc = videoEl.srcObject as MediaStream;
    if(!videoSrc) {
      return;
    }
    videoEl.srcObject = null;
  });

  //
  // Re-compute the media layout:
  //   1. When the component initially renders
  //   2. When configStore.media1ScalingMode changes (contain | cover)
  //   3. When mediaTrackSettings change (because the source media changed)
  //
  // useEffect(() => {
  //   handleMediaPanelResized();
  // }, [handleMediaPanelResized, configStore.media1ScalingMode, mediaDimensions]);

  //
  // Capture a snapshot of the current video background.
  //
  const hasAvailableVideoSource = () => {
    const videoEl = videoRef.current;
    const canvasEl = canvasRef.current;
    if(!videoEl || videoEl.srcObject === null || !canvasEl) {
      createOrUpdateToast('capture-still-unavailable', 'No video source for capture...', {
        type: 'error',
        isLoading: false,
        autoClose: 3000,
        hideProgressBar: false,
      });
      return false;
    }

    return true;
  };

  const [countdownNumber, setCountdownNumber] = useState<number>();
  useMessageHandler('prompter.background.snapshot.countdown', () => {
    if(!hasAvailableVideoSource()) {
      return;
    }

    setCountdownNumber(3);
  });

  useMessageHandler('prompter.background.snapshot.immediate', () => {
    if(!hasAvailableVideoSource()) {
      return;
    }

    setCountdownNumber(0);
  });

  const countdownRef = useRef<HTMLSpanElement>(null);
  const countdownTimerRef = useRef<number>();
  useEffect(() => {
    if(countdownNumber === undefined) {
      return;
    }

    if(countdownNumber < 0) {
      setCountdownNumber(undefined);

      const videoEl = videoRef.current;
      const canvasEl = canvasRef.current;
      if(!videoEl || videoEl.srcObject === null || !canvasEl) {
        console.error('no videoEl, videoEl.srcObject or canvasEl');
        return;
      }

      // CAPTURE SNAPSHOT NOW!!
      const canvasContext = canvasEl.getContext('2d');
      if(!canvasContext) {
        console.error('no canvasContext');
        return;
      }

      const videoWidth = videoEl.videoWidth;  // 1920;
      const videoHeight = videoEl.videoHeight;  // 1080;

      canvasEl.width = videoWidth;
      canvasEl.height = videoHeight;

      // console.log(`videoWidth: ${videoWidth}, videoHeight: ${videoHeight}`)
      // canvasContext.drawImage(videoEl, 0, 0, canvasEl.width, canvasEl.height);
      canvasContext.drawImage(videoEl, 0, 0, videoWidth, videoHeight);
      const image_data_url = canvasEl.toDataURL('image/jpeg');

      const now = new Date();
      const nowISO = now.toISOString();

      const element = document.createElement('a');
      element.setAttribute('href', image_data_url);
      element.setAttribute('download', `FluidPrompter Photo - ${nowISO}.jpg`);
      element.style.display = 'none';

      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);

      // window.URL.revokeObjectURL(objectURL);

      return;
    }

    const countdownEl = countdownRef.current;
    if(countdownEl) {
      // https://css-tricks.com/restart-css-animation/
      countdownEl.classList.remove('pulse-animation');
      void countdownEl.offsetWidth;
      countdownEl.classList.add('pulse-animation');
    }

    if(countdownNumber >= 0) {
      const nextNumber = countdownNumber - 1;
      countdownTimerRef.current = window.setTimeout(() => {
        setCountdownNumber(nextNumber);
      }, 1000);
    }

    return () => {
      window.clearTimeout(countdownTimerRef.current);
    };
  }, [countdownNumber, videoRef, canvasRef]);

  const mediaPanelIsLandscape = configStore.mediaPanelPosition === MediaPanelPosition.Top
    || configStore.mediaPanelPosition === MediaPanelPosition.Bottom;
  const mediaPanelIsPortrait = configStore.mediaPanelPosition === MediaPanelPosition.Left
    || configStore.mediaPanelPosition === MediaPanelPosition.Right;

  let mediaPanelPosition = configStore.mediaPanelPosition;
  if(configStore.flipHorizontal) {
    switch(mediaPanelPosition) {
      case MediaPanelPosition.Left:
        mediaPanelPosition = MediaPanelPosition.Right;
        break;
      case MediaPanelPosition.Right:
        mediaPanelPosition = MediaPanelPosition.Left;
        break;
    }
  }
  if(configStore.flipVertical) {
    switch(mediaPanelPosition) {
      case MediaPanelPosition.Top:
        mediaPanelPosition = MediaPanelPosition.Bottom;
        break;
      case MediaPanelPosition.Bottom:
        mediaPanelPosition = MediaPanelPosition.Top;
        break;
    }
  }

  /*
  let mediaPanelCssTop: string | number | undefined;
  if([MediaPanelPosition.Top, MediaPanelPosition.Bottom].indexOf(configStore.mediaPanelPosition) >= 0) {

    if(
      (configStore.mediaPanelPosition === MediaPanelPosition.Bottom && !configStore.flipVertical)
        || (configStore.mediaPanelPosition === MediaPanelPosition.Top && configStore.flipVertical)
    ) {
      mediaPanelCssTop = `${100 - configStore.mediaPanelHeight}vh`
    }
    mediaPanelCssTop = configStore.flipVertical
      ? 0
      : `${100 - configStore.mediaPanelHeight}vh`;
  }
  */

  const mediaPanelActive = prompterSession.media1SourceType !== MediaSourceType.None;
  const verticalOrientation = mediaPanelPosition === MediaPanelPosition.Left || mediaPanelPosition === MediaPanelPosition.Right;

  //
  // Figure out our list of CSS Transforms that apply any configured flipping
  // horizontally or vertically as well as scroll position.
  //
  const transformDirectives: string[] = [];
  switch(mediaPanelPosition) {
    case MediaPanelPosition.Top:
      transformDirectives.push(mediaPanelActive ? 'translateY(0)' : 'translateY(-100%)');
      break;
    case MediaPanelPosition.Left:
      transformDirectives.push(mediaPanelActive ? 'translateX(0)' : 'translateX(-100%)');
      break;
    case MediaPanelPosition.Right:
      transformDirectives.push(mediaPanelActive ? 'translateX(0)' : 'translateX(100%)');
      break;
    case MediaPanelPosition.Bottom:
      transformDirectives.push(mediaPanelActive ? 'translateY(0)' : 'translateY(100%)');
      break;
    default:
    case MediaPanelPosition.Background:
      break;
  }
  if(configStore.flipHorizontal) { transformDirectives.push('rotateY(180deg)'); }
  if(configStore.flipVertical) { transformDirectives.push('rotateX(180deg)'); }
  const contentTransform = transformDirectives.join(' ');

  const [dragHandleActive, setDragHandleActive] = useState(false);

  return (<div ref={mediaPanelRef}
    className={classNames('MediaPanelContainer',
      `MediaPanelPosition-${mediaPanelPosition}`,
      {
        FlipHorizontal: configStore.flipHorizontal,
        FlipVertical: configStore.flipVertical,
        MediaActive: prompterSession.media1SourceType !== MediaSourceType.None,
        PrompterBlanking: prompterSession.isBlanking,
        // Contain: (backgroundState.backgroundLayout === MediaScalingMode.Contain),
        // Cover: (backgroundState.backgroundLayout === MediaScalingMode.Cover),
      }
    )}
    style={{
      width: !mediaPanelIsPortrait ? '100%' : `${configStore.mediaPanelWidth}%`,
      height: !mediaPanelIsLandscape ? '100%' : `${configStore.mediaPanelHeight}%`,
      display: 'flex',
      flexDirection: verticalOrientation ? 'column' : 'row',
      justifyContent: 'center',
      transform: contentTransform,
    }}
  >
    {mediaStream && <VideoPanel
      mediaStream={mediaStream}
      hideToolbar={prompterSession.cursorHidden}
    />}
    <canvas
      ref={canvasRef}
    > </canvas>
    {countdownNumber !== undefined && <div
      className={classNames('CountdownContainer', { CountdownFlash: (countdownNumber === 0) })}
    >
      {countdownNumber > 0 && <span
        ref={countdownRef}
        className="CountdownNumber"
      >{countdownNumber}</span>}
    </div>}
    <MediaPanelToolbar hideToolbar={prompterSession.cursorHidden || viewportSmOrXs || mediaPanelPosition === MediaPanelPosition.Background} showContextMenuAtAnchor={showContextMenuAtAnchor} />
    <MediaPanelContextMenu {...contextMenuProps} closeContextMenu={closeContextMenu} />
    <div
      className={classNames('dragHandle allow-click-while-prompting', {
        dragHandleHidden: prompterSession.cursorHidden,
        dragHandleActive
      })}
      onTouchStart={(e) => {
        e.preventDefault();
        e.stopPropagation();

        // When we start dragging the drag handle, we want to cancel any other touch gestures, such
        // as the long touch for context menu.
        cancelTouchGestures();
      }}
      onPointerDown={(e) => {
        e.preventDefault();

        //
        // We don't want the cursor hide timeout to elapse as long as we are actively dragging the
        // resize handle.
        //
        appController.dispatchMessage('prompter.local.HideCursorTimer.Restart');

        e.currentTarget.setPointerCapture(e.pointerId);
        setDragHandleActive(true);
      }}
      onPointerUp={(e) => {
        if(!dragHandleActive) {
          return;
        }

        e.preventDefault();
        e.currentTarget.releasePointerCapture(e.pointerId);
        setDragHandleActive(false);
      }}
      onPointerMove={(e) => {
        if(!dragHandleActive) {
          return;
        }

        //
        // We don't want the cursor hide timeout to elapse as long as we are actively dragging the
        // resize handle.
        //
        appController.dispatchMessage('prompter.local.HideCursorTimer.Restart');

        //
        // Calculate the new MediaPanel dimension based on the distance the pointer had moved while
        // the pointer was down.
        //
        switch(mediaPanelPosition) {
          case MediaPanelPosition.Top:
            useConfigurationStore.getState()
              .setMediaPanelHeight(Math.round(e.clientY * 100 / window.innerHeight));
            break;
          case MediaPanelPosition.Bottom:
            useConfigurationStore.getState()
              .setMediaPanelHeight(Math.round((window.innerHeight - e.clientY) * 100 / window.innerHeight));
            break;
          case MediaPanelPosition.Left:
            useConfigurationStore.getState()
              .setMediaPanelWidth(
                Math.round(e.clientX * 100 / window.innerWidth)
              );
            break;
          case MediaPanelPosition.Right:
            useConfigurationStore.getState()
              .setMediaPanelWidth(
                Math.round((window.innerWidth - e.clientX) * 100 / window.innerWidth)
              );
            break;
        }
      }}
    ></div>
    <div className='iOS-bottom-filler'></div>
  </div>);
});

export default PrompterMediaPanel;