import { useCallback } from 'react';
import { useMessageHandler, MessageHandlerEvent } from '../../controllers/AppController';

import { IViewportRefs } from './usePrompterViewportRefs';
import { IViewportFuncs } from './usePrompterViewportFuncs';
import usePrompterSession from '../../state/PrompterSessionState';

import { EndpointRole, NavigateMessage } from '@fluidprompter/core';
import { ElementTypes, PrompterElement } from '../../models/EditorTypes';
import { Descendant } from 'slate';

function usePrompterNavigateToStart(viewportRefs: IViewportRefs, viewportFuncs: IViewportFuncs) {

  const handleNavigateToSpecificPosition = useCallback(function (e: MessageHandlerEvent<NavigateMessage>): void {
    const { message, /*senderState, originatedRemotely*/ } = e;
    const { targetPosition, scrollPosition, scrollBehavior, scrollTime, scrollOffset, suspendPlayingForMs, segmentIndex, contentNumber, scriptPosition } = message;

    const prompterSession = usePrompterSession.getState();

    //
    // Handle IScriptPosition scroll targets that are relative to a nodePath, nodePosition, etc.
    //
    if(scriptPosition !== undefined) {
      const { viewportMeta } = prompterSession;

      const viewportPosition = prompterSession.getScrollPositionByScriptPosition(scriptPosition);
      let targetScrollPosition = viewportPosition?.scrollPosition;
      if(targetScrollPosition !== undefined && viewportMeta) {
        targetScrollPosition -= viewportMeta.cuePositionOffset;

        viewportFuncs.scrollToPosition({
          scrollPosition: targetScrollPosition,
          scrollBehavior: scrollBehavior || 'smooth',
          scrollTime,
          suspendPlayingForMs,
        });
        return;
      }
    }

    //
    // Handle a pixel offset from the current scroll position, positive or negative.
    //
    if(scrollOffset !== undefined) {
      const { scrollPosition } = prompterSession;

      if(scrollPosition !== undefined) {
        e.sendToPeers = false;
        viewportFuncs.scrollToPosition({
          scrollPosition: scrollPosition + scrollOffset,
          scrollBehavior: scrollBehavior || 'smooth',
          scrollTime,
          suspendPlayingForMs,
        });
        return ;
      }
    }

    //
    // Handle a scrollPosition expressed as pixels from the top of the prompter content.
    // This method should really only be use locally (as it doesn't scale between prompters)
    //
    if(scrollPosition !== undefined) {
      //
      // We never send specific pixel scroll positions to peer prompters, we send the higher level
      // abstract messages for navigate to segment or script position epressed relative to a script
      // node.
      //
      // This navigate message may have been dispatched by sync adjustments keeping this prompter
      // inline with the leader.
      //
      e.sendToPeers = false;
      viewportFuncs.scrollToPosition({
        scrollPosition,
        scrollBehavior: scrollBehavior || 'smooth',
        scrollTime,
        suspendPlayingForMs,
      });
      return;
    }

    let targetSegment: Descendant | undefined;  // = args.segmentInstance;

    if(!targetSegment && segmentIndex !== undefined) {
      if(prompterSession.scriptNodes.length && prompterSession.scriptNodes.length > segmentIndex) {
        targetSegment = prompterSession.scriptNodes[segmentIndex];
      }
    }

    if(!targetSegment && contentNumber) {
      const textSegments = prompterSession.scriptNodes.filter((segment) => (segment as PrompterElement).type === ElementTypes.SCRIPT_SEGMENT);
      if(textSegments.length && textSegments.length >= contentNumber) {
        targetSegment = textSegments[contentNumber - 1];
      }
    }

    if(targetSegment === undefined) {
      return;
    }

    // const scrollPromise = viewportFuncs.scrollToSegment(targetSegment);
    // const navigationPromise = viewportFuncs.scrollToSegment(targetSegment, targetPosition || 'cue');

    viewportFuncs.scrollToSegment(targetSegment, targetPosition || 'cue');
    return;
  }, [viewportFuncs]);

  const handleNavigateMessage = useCallback(async function (e: MessageHandlerEvent<NavigateMessage>) {
    const { message, originatedRemotely } = e;
    const { queueSequentialTask } = message;

    const prompterSession = usePrompterSession.getState();

    //
    // If we receive Play/Pause/Edit/Navigate commands from a remote peer, that peer is currently
    // acting as prompter leader.
    //
    // If this message was sent by a prompter (and not a headless remote), then we will re-evaluate
    // whether we are the current leader or not.
    const isLeader = e.checkIAmLeader(prompterSession);

    switch(message.target) {
      case NavigateMessage.Target.Start: {
        e.dispatchMessage(new NavigateMessage(NavigateMessage.Target.Position, {
          segmentIndex: 0
        }));
        break;
      }
      case NavigateMessage.Target.End: {
        const targetSegmentIndex = prompterSession.scriptNodes.length - 1;
        e.dispatchMessage(new NavigateMessage(NavigateMessage.Target.Position, {
          segmentIndex: targetSegmentIndex
        }));
        break;
      }
      case NavigateMessage.Target.PageUp: {
        const currentScrollPosition = viewportRefs.previousLedgerRef.current?.scrollPosition || 0;

        // If this message originated remotely, only the current leader should process the message.
        // This would be the case for a headless remote sending the message.
        if(originatedRemotely && !isLeader) {
          return;
        }

        // This should never happen except like a race condition on first paint of the browser.
        const { viewportMeta } = prompterSession;
        const { viewportHeight } = viewportMeta;

        let scrollPosition = currentScrollPosition - (viewportHeight * 0.8);
        if(scrollPosition < 0) { scrollPosition = 0; }

        scrollPosition += viewportMeta.cuePositionOffset;

        const scriptPosition = prompterSession.getScriptPositionByScrollPosition(scrollPosition);
        // console.log(`scriptPosition at ${scrollPosition}px`, scriptPosition);
        e.dispatchMessage(new NavigateMessage(NavigateMessage.Target.Position, {
          scriptPosition,
        }));
        break;
      }
      case NavigateMessage.Target.PageDown: {
        const currentScrollPosition = viewportRefs.previousLedgerRef.current?.scrollPosition || 0;

        // If this message originated remotely, only the current leader should process the message.
        // This would be the case for a headless remote sending the message.
        if(originatedRemotely && !isLeader) {
          return;
        }

        // This should never happen except like a race condition on first paint of the browser.
        const { viewportMeta, scriptNodesMeta } = prompterSession;
        if(!scriptNodesMeta) {
          return;
        }

        const { viewportHeight } = viewportMeta;

        let scrollPosition = currentScrollPosition + (viewportHeight * 0.8);

        const maxScrollPosition = scriptNodesMeta.contentHeight - viewportHeight;
        if(scrollPosition > maxScrollPosition) { scrollPosition = maxScrollPosition; }

        scrollPosition += viewportMeta.cuePositionOffset;

        const scriptPosition = prompterSession.getScriptPositionByScrollPosition(scrollPosition);
        // console.log(`scriptPosition at ${scrollPosition}px`, scriptPosition);
        e.dispatchMessage(new NavigateMessage(NavigateMessage.Target.Position, {
          scriptPosition,
        }));
        break;
      }
      case NavigateMessage.Target.PrevSegment: {  // Previous Segment
        // We will figure out the relative segment index, then dispatch a new NavigateMessage()
        // with an explicit targetSegmentIndex.
        // This ensure remote peers are properly in-sync even if they weren't before this message.

        const targetSegmentIndex = viewportFuncs.getSegmentIndexRelativeToActive(-1);
        // console.log(`navigate to next segment at index ${targetSegmentIndex}`);

        e.dispatchMessage(new NavigateMessage(NavigateMessage.Target.Position, {
          segmentIndex: targetSegmentIndex
        }));
        break;
      }
      case NavigateMessage.Target.NextSegment: {  // Next Segment
        // We will figure out the relative segment index, then dispatch a new NavigateMessage()
        // with an explicit targetSegmentIndex.
        // This ensure remote peers are properly in-sync even if they weren't before this message.

        const targetSegmentIndex = viewportFuncs.getSegmentIndexRelativeToActive(1);
        // console.log(`navigate to next segment at index ${targetSegmentIndex}`);

        e.dispatchMessage(new NavigateMessage(NavigateMessage.Target.Position, {
          segmentIndex: targetSegmentIndex
        }));
        break;
      }
      case NavigateMessage.Target.Segment: {
        const segmentIndex = prompterSession.scriptNodes.findIndex((value) => value === e.message.segmentInstance);
        if(segmentIndex < 0) {
          // Invalid
          // throw new Error('NavigateMessage.Target.Segment Not implemented yet.');
          return;
        }

        e.dispatchMessage(new NavigateMessage(NavigateMessage.Target.Position, {
          segmentIndex
        }));
        break;
      }
      case NavigateMessage.Target.Position: {
        e.sendToPeers = isLeader && !e.originatedRemotely;

        if(queueSequentialTask) {
          viewportFuncs.queueSequentialTask(async () => {
            handleNavigateToSpecificPosition(e);
            if(e.originatedRemotely) {
              e.syncScrollSpeed();
            }
          });
          return;
        }

        handleNavigateToSpecificPosition(e);
        if(e.originatedRemotely) {
          e.syncScrollSpeed();
        }
      }
    }

  }, [viewportFuncs]);

  useMessageHandler('navigate', handleNavigateMessage);
}

export default usePrompterNavigateToStart;