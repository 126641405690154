import { Descendant, Range } from 'slate';
import { ReactEditor } from 'slate-react';
import { HistoryEditor } from 'slate-history';
import { CursorEditor, YHistoryEditor, YjsEditor } from '@slate-yjs/core';

export enum ElementTypes {
  STARTSCRIPT = 'startscript',
  ENDSCRIPT = 'endscript',
  SCRIPT_SEGMENT = 'promptersegment', // previously 'promptersegment' => 'scriptsegment'
  PAUSE = 'pause',
  PARAGRAPH = 'paragraph',
  HYPERLINK = 'hyperlink',
  BROLL = 'broll',
  IMAGE = 'image',
  CODE_BLOCK = 'inline-code',
  BLOCK_QUOTE = 'block-quote',
}

export type CursorData = {
  name: string;
  color: string;
};

/**
 * The list of supported marks for PrompterText
 */
export type PrompterTextMarks = {
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  strike?: boolean;
  highlight?: boolean;
  code?: boolean;

  isSelection?: boolean;    // If true, this decoration range is a remote editor selection.
  isCursor?: boolean;       // If true, the remote editor cursor is visible on this range
  placeholder?: boolean;  // This is only true when our slate decorator has flagged this as an empty leaf node and we want to show `Type '/' for menu` placeholder text.
}

/**
 * Type definition for script leaf nodes.
 */
export type PrompterText = PrompterTextMarks & {
  masked?: boolean; // Text will be rendered masked until voice recognition provides final result. TODO: Create a custom editor element to contain pending voice dictation results?
  kbd?: boolean;  // TODO: This may get moved to be an inline-void type for Slate.
  text: string;

  [index: string]: unknown; // Allow this type to be indexed by string name - used to check the enabled marks.
};

export type PrompterDecoration = Range & PrompterTextMarks;

export type StartScript = {
  type: ElementTypes.STARTSCRIPT;
  children: Descendant[];
};

export type EndScript = {
  type: ElementTypes.ENDSCRIPT;
  children: Descendant[];
};

export type PausePosition = 'CUE' | 'TOP' | 'BOTTOM';
export type Pause = {
  type: ElementTypes.PAUSE;
  pausePosition?: PausePosition,
  children: Descendant[];
};

export type PrompterSegment = {
  type: ElementTypes.SCRIPT_SEGMENT;
  number?: number,
  title?: string,
  children: Descendant[];
};

export type Paragraph = {
  type: ElementTypes.PARAGRAPH;
  children: Descendant[];
};

export type Hyperlink = {
  type: ElementTypes.HYPERLINK;
  children: Descendant[];
};

export type BRoll = {
  type: ElementTypes.BROLL;
  description: string;
  // Location
  // Camera
  // Lens
  // Lighting
  // interior/exterior
  // shot type/framing (Framing): wide shot | medium | tight | etc
  // Camera Angle (Angle): High | Eye Level | Low
  // Camera Move (Action): Static | Pan | Tilt | Zoom | Follow | Jib | Slider | Track Dolly | etc
  // Audio: Boom | Mic | Shotgun | Lav | OC | VO
  // Actors/Subject
  // Props Required
  children: Descendant[];
};

export type Image = {
  type: ElementTypes.IMAGE;
  url: string;
  caption: string;
  isUploading?: boolean;
  children: Descendant[];
};

export type InlineCode = {
  type: ElementTypes.CODE_BLOCK;
  children: Descendant[];
};

export type HeadingOne = {
  type: 'heading-one';
  children: Descendant[];
};

export type HeadingTwo = {
  type: 'heading-two';
  children: Descendant[];
};

export type BlockQuote = {
  type: ElementTypes.BLOCK_QUOTE;
  children: Descendant[];
};

export type PrompterElement =
  | StartScript
  | EndScript
  | Pause
  | PrompterSegment
  | Paragraph
  | Hyperlink
  | BRoll
  | Image
  | InlineCode
  | HeadingOne
  | HeadingTwo
  | BlockQuote;

export type PrompterEditor = ReactEditor &
  HistoryEditor &
  YjsEditor &
  YHistoryEditor &
  CursorEditor<CursorData>;

declare module 'slate' {
  interface CustomTypes {
    Editor: PrompterEditor;
    Element: PrompterElement;
    Text: PrompterText;
  }
}
