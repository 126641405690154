/**
 * We compress PrompterMode in order to shorten the length of serialized JSON (to ensure all
 * messages fit in 1 network packet, 1 MTU), but also as a bit of obfuscation to deter reverse
 * engineering.
 *
 * The string value of the enum is only used for serialization/deserialization. Any code reference
 * should be readable as it uses the enum key.
 */
export var PrompterMode;
(function (PrompterMode) {
    PrompterMode["Editing"] = "e";
    PrompterMode["Playing"] = "p";
    PrompterMode["Paused"] = "z";
})(PrompterMode || (PrompterMode = {}));
export default PrompterMode;
