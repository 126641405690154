import React from 'react';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import NoticeBar from './NoticeBar';

import useApplicationContext from '../../hooks/useApplicationContext';
import { Trans, useTranslation } from 'react-i18next';

interface NoticeBillingUpdateRequiredProps {
  billingExpiredAlready?: boolean;
  billingPastDue: boolean;
  requestClose?: () => void;
}

function NoticeBillingUpdateRequired(props: NoticeBillingUpdateRequiredProps) {
  const { billingExpiredAlready, billingPastDue, requestClose } = props;

  const { t } = useTranslation('prompter');

  const {
    subscription,
  } = useApplicationContext(true);

  let noticeMsg = t('noticebars.billing_update_required.billing_expiring');
  if(billingExpiredAlready) {
    noticeMsg = t('noticebars.billing_update_required.billing_expired');
  }
  if(billingPastDue) {
    noticeMsg = t('noticebars.billing_update_required.billing_past_due');
  }

  // If we are NOT past due, this notice is user dismissable (may be hidden without taking
  // corrective action). However, we the user is past due, the notice cannot be hidden without
  // updating their payment method.
  const requestCloseHandler: (() => void) | undefined = billingPastDue ? undefined : requestClose;

  return (
    <NoticeBar
      type='warning'
      startIcon={<ErrorOutlineIcon /*color='warning'*/ style={{ color: '#b6b600' }} />}
      ctaShortCaption={t('noticebars.billing_update_required.cta_short')}
      ctaCaption={t('noticebars.billing_update_required.cta_full')}
      ctaAction={() => {
        subscription.startUpdatePaymentMethod();
      }}
      requestClose={requestCloseHandler}
    >{noticeMsg}</NoticeBar>
  );
}

export default NoticeBillingUpdateRequired;