import React, { useEffect, useState } from 'react';

import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import NoticeBar from './NoticeBar';

import useApplicationContext from '../../hooks/useApplicationContext';
import useApplicationState from '../../state/ApplicationState';
import { Trans, useTranslation } from 'react-i18next';

interface NoticeEmailVerificationRequired {
  requestClose?: () => void;
}

function NoticeEmailVerificationRequired(props: NoticeEmailVerificationRequired) {
  const { requestClose } = props;

  const { t } = useTranslation('prompter');

  const {
    fetchUserProfile,
  } = useApplicationContext(true);

  //
  // Watch the window focus to see if the user leaves and returns. When they return, let's check if
  // they completed email verification while gone.
  //
  const [cssAnimationClass, setCssAnimationClass] = useState<string>();
  useEffect(() => {

    const focusHandler = async () => {
      const currentUserProfile = await fetchUserProfile();

      if(currentUserProfile?.email_verified === true) {
        if(requestClose) { requestClose(); }
        return;
      }

      setCssAnimationClass('Shake');
    };

    window.addEventListener('focus', focusHandler);

    return () => {
      window.removeEventListener('focus', focusHandler);
    };
  }, [fetchUserProfile]);

  //
  // When the user clicks the "Verify Now" button/link we will first re-fetch the user profile. If
  // they did verify their email, then hide the notice. If they still did not verify their email,
  // show the verify email dialog with option to resend verification email.
  //
  const ctaAction = async () => {
    const currentUserProfile = await fetchUserProfile();

    if(currentUserProfile?.email_verified === true) {
      if(requestClose) { requestClose(); }
      return;
    }

    useApplicationState.getState().setVerifyEmailOpen(true);
  };

  return (
    <NoticeBar
      type='warning'
      animationName={cssAnimationClass}
      onAnimationEnd={(e) => {
        // Remove any CSS class used for animation such as 'shake' animation to highlight the notice bar.
        if(e.animationName === cssAnimationClass) {
          setCssAnimationClass(undefined);
        }
      }}
      startIcon={<ReportGmailerrorredIcon /*color='warning'*/ style={{ color: '#b6b600' }} />}
      ctaShortCaption={t('noticebars.email_verification_required.cta_short')}
      ctaCaption={t('noticebars.email_verification_required.cta_full')}
      ctaAction={ctaAction}
      requestClose={requestClose}
    >{t('noticebars.email_verification_required.message')}</NoticeBar>
  );
}

export default NoticeEmailVerificationRequired;