import React from 'react';

import { Trans, useTranslation } from 'react-i18next';
import usePrompterSession from '../../../state/PrompterSessionState';
import { shallow } from 'zustand/shallow';

import './VirtualCursors.scss';

/**
 * Renders virtual cursors for remote editor peer(s).
 * @returns
 */
function VirtualCursors() {

  const { t } = useTranslation('prompter');

  const prompterSession = usePrompterSession(state => ({
    remoteEditorFocused: state.remoteEditorFocused,
    remoteCursors: state.virtualCursors,
  }), shallow);

  return (<>
    {prompterSession.remoteEditorFocused && prompterSession.remoteCursors.map((cursor, index) => (<div
      key={`cursor${index}`}
      className='VirtualCursor'
      style={{
        top: `${cursor.top}px`,
        left: `${cursor.left}px`,
        height: `${cursor.height}px`,
      }}
    >
      <div className='CursorCaret' />
      <div className='CursorLabel'>EDITOR</div>
    </div>))
    }
  </>);
}

export default VirtualCursors;